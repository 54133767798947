<template>
  <div>
    <div class="modal" v-if="isShow" v-html="message"></div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      message: "",
      isShow: false,
    };
  },
  methods: {
    Toast(msg = "默认弹框", duration = 1500) {
      this.isShow = true;
      this.message = msg;
      setTimeout(() => {
        this.isShow = false;
        this.message = "";
      }, duration);
    },
  },
};
</script>

<style lang="less" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%,-50%);
  padding: 12px 25px;
  text-align: center;
  width: 150px;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
  background-color: #000;
  opacity: 0.6;
}
</style>
