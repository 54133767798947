<template>
  <div :style="`opacity:${config.site_id ? 1 : 0};`">
    <Footer360 v-if="config.pc_template_id == 5" />
    <FooterDefault v-if="config.pc_template_id != 5" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import Footer360 from './temp/360/index.vue';
import FooterDefault from './temp/default/index.vue';
export default {
  data() {
    return {
    };
  },
  name: "header",
  props: {},
  components: {
    Footer360 : () => import("./temp/360/index.vue"),
    FooterDefault
  },
  computed: {
    ...mapGetters(["marketGroup", "config"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>