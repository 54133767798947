<template>
  <div v-if="!isHide && config.site_id && config.pc_index_lead_switch == 1">
    <div class="act-user-modal">
      <span class="close" @click="handleHide"
        >
        <icon-font style="font-size: 30px; color: #fff" type="icon-hide"></icon-font
      >
      </span>
      <h3>
        <img
          :alt="config.site_name || config.siteName"
          :src="
            config.logo ||
              'https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug0.png'
          "
        />
      </h3>
      <div class="center">
        <h1>
          欢迎来到{{ config.site_name || config.siteName || "您的导购商城" }}
        </h1>
        <p class="desc">一款专门为您省钱的官方导购网站</p>
        <div class="nr">
          每日更新数万款天猫淘宝大额优惠券，领券狂省低至1折，想要什么一键全网搜索。精选大牌底价开团，聚划算买一送一，第二件0元，海量折扣应有尽有。专业人工团队严控产品质，帮你花更少钱买好货。
        </div>
      </div>
      <ul>
        <li>
          <img
            src="https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug3.png"
          />
          <p class="name">领券购物省钱</p>
          <p class="test">
            每日更新海量天猫淘宝大额优惠券，领券购物低至1折，让你花少钱买到心仪好物
          </p>
        </li>
        <li>
          <img
            src="https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug2.png"
          />
          <p class="name">精选爆款栏目</p>
          <p class="test">
            聚划算精品折上折、疯抢排行、 每日半价、咚咚抢…海量惊喜折扣等你体验
          </p>
        </li>
        <li>
          <img
            src="https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug1.png"
          />
          <p class="name">大牌优惠好货</p>
          <p class="test">
            每日更新优质好货、百人品控团队、只为提供超高性价比的前沿好物
          </p>
        </li>
      </ul>
      <button class="btn" @click="handleHide">我知道了~</button>
    </div>
    <div class="act-user-modal-bg"></div>
  </div>
</template>

<script>
import IconFont from "@/components/IconFont.vue";
import { mapGetters } from "vuex";
import { importCDN } from "@/utils/utils";
export default {
  components: {
    IconFont,
  },
  data() {
    return {
      isHide: true,
    };
  },
  computed: {
    ...mapGetters(["config"]),
  },
  methods: {
    handleHide() {
      window.localStorage.setItem("CMS-ACT-MODAL", "1");
      this.isHide = true;
    },
  },
  mounted() {
    const isHide = window.localStorage.getItem("CMS-ACT-MODAL");
    if (!isHide) {
      this.isHide = false;
    }
  },
};
</script>

<style lang="less" scoped>
.act-user-modal {
  width: 900px;
  height: 570px;
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 9000;
  background: url(https://sr.ffquan.cn/dtk_www/20211209/c6on489alsbijbagcnu02.png)
    no-repeat center center;
  margin: -285px 0 0 -450px;
  .close {
    position: absolute;
    top: -24px;
    right: 12px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    font-size: 18px;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
  }
  img {
    display: block;
    margin: 0 auto;
  }
  h3 {
    padding: 21px 0 0 92px;
    margin: auto;
    text-align: left;
    img {
      margin: 0;
      height: 50px;
    }
  }
  .center {
    height: 240px;
    h1 {
      text-align: center;
      font-size: 34px;
      color: #333;
      margin: 0 auto;
      width: 700px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .desc {
      text-align: center;
      font-size: 20px;
      padding: 10px 0 10px;
    }
    .nr {
      width: 580px;
      text-align: center;
      font-size: 14px;
      line-height: 26px;
      color: #777;
      margin: auto;
    }
  }
  ul {
    display: flex;
    width: 640px;
    margin: auto;
    justify-content: space-around;
    li {
      width: 130px;
      color: #fff;
      text-align: center;
      img {
        margin-bottom: 10px;
        height: 72px;
      }
      .name {
        font-size: 19px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .text {
        font-size: 13px;
        opacity: 0.8;
        line-height: 21px;
      }
    }
  }
  .btn {
    width: 180px;
    height: 50px;
    background: #ffffff;
    border-radius: 37.5px;
    color: #df1f39;
    font-size: 19px;
    text-align: center;
    line-height: 50px;
    margin: auto;
    border: 0;
    display: block;
    position: relative;
    z-index: 0;
    top: 45px;
    cursor: pointer;
    &:hover {
      background: #f1f1f1;
    }
  }
}
.act-user-modal-bg {
  background: #000;
  opacity: 0.8;
  position: fixed;
  left: 0;
  z-index: 8999;
  top: 0;
  width: 100%;
  height: 100%;
}
</style>
