/*
 * @Author: your name
 * @Date: 2021-08-13 16:06:14
 * @LastEditTime: 2021-12-01 17:51:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/directive/index.js
 */

import { AccAdd, importCDN } from "@/utils/utils";
import "intersection-observer";
import qs from 'qs';
const { Toast } = {}

Vue.directive("jump", {
  // 通用跳转
  inserted: function(el, item, vnode, oldVnode) {
    if(el.getAttribute('target') == '_blank' && item.value.url){
      el.setAttribute("href", `${item.value.url}`);
      return 
    }
    el.onclick = () => {
      if (item.value.in == 1) {
        const url = `${item.value.url}`.indexOf('r=') > -1 ? `${item.value.url}`.split('?')[1] : `r=${item.value.url}`;
        const {r,...props} = qs.parse(url);
        window.$myApp.$router.push({
          path:r,
          query:props
        })
      } else {
        window.location.href = item.value.url;
      }
    };
  },
});

Vue.directive("href", {
  // 通用跳转
  inserted: function(el, item, vnode, oldVnode) {
    el.onclick = () => {
      window.location.href = `?r=l/d&id=${item.value}`;
    };
  },
});

Vue.directive("lazy", {
  // 指令的定义
  bind: function(el, item) {
    el.src = "https://sr.ffquan.cn/cms_pic/20210813/c4b0drf6vrkdtgsh7q100.gif";
    el.setAttribute("data-src", item.value);
    let lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry, index) => {
        let lazyImage = entry.target;
        // 相交率，默认是相对于浏览器视窗
        if (entry.intersectionRatio > 0) {
          lazyImage.src = item.value;
          // 当前图片加载完之后需要去掉监听
          lazyImageObserver.unobserve(lazyImage);
        }
      });
    });
    lazyImageObserver.observe(el);
  },
});

Vue.directive("back", {
  inserted: function(el, item, vnode, oldVnode) {
    el.onclick = () => {
      if (typeof item.value === "function") {
        return item.value();
      }
      let value = item.value || "back";
      switch (value) {
        case "back":
          return vnode.context.$router.back();
          break;
        default:
          break;
      }
    };
  },
});

Vue.directive("copy", {
  bind: function(el, binding, vnode) {
    importCDN(
      "https://public.ffquan.cn/lib/clipboard.min.js"
    ).then((res) => {
      const clipboard = new window.ClipboardJS(el, {
        text: (trigger) => {
          return el.dataset.copytext;
        },
      });
      clipboard.on("success", function(e) {
        Toast("复制成功");
      });
      clipboard.on("error", function(e) {
        Toast("复制失败");
      });
    });
  },
});
// 商品卡片通用活动 标签
Vue.directive("marketgroup", {
  // 指令的定义
  bind: function(el, event) {

    if( typeof window.marketGroupConfig === 'undefined'){
      let index = 0;
      const setTime = setInterval(() => {
        if(typeof window.marketGroupConfig !== 'undefined' || index > 5){
          renderMarketGroup(el,event);
          clearInterval(setTime)
        }
        index ++;
      }, 1000);
    }
    
    const renderMarketGroup = ($el,$event)=>{
      const items = $event.value;
      

      const marketGroupConfig = window.marketGroupConfig || [];
      const marketGroup =(items.marketGroup||[]).map(res=>marketGroupConfig[res]).filter(res=>res)[0];

      const size = $el.getAttribute('size') || 'max';
      if(!marketGroup) return false;
      $el.className = `${$el.className} market-pic`;
  
      
      const Dom = document.createElement('div');
      if(items.quanMLink && size=='max' && marketGroup.goods_type == 2){
        Dom.innerHTML = `<span >定金${ items.quanMLink }抵${ AccAdd({
          num: [items.quanMLink, items.hzQuanOver || 0],
        }) }</span>`;
      }

      // 只有首页咚咚抢活动标签&特色文案优先级判断
      if(Dom && $el.previousElementSibling && $el.previousElementSibling.className == 'lowPrice'){
        $el.previousElementSibling.style.display = 'none'
      }
      
      Dom.className = `market-tag ${size=='max' ? '':'market-small'}`;
      Dom.style = `background-image:url('${size=='max' ? marketGroup.pc_main_big : marketGroup.pc_main_small}')`
      $el.appendChild(Dom)
    }
    renderMarketGroup(el,event);
    
  },
});