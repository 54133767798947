/*
 * @Author: your name
 * @Date: 2020-10-26 19:17:08
 * @LastEditTime: 2021-12-02 17:12:43
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_php_deliver/web/web_view/src/utils/request.js
 */
import DtkSdk from "@/utils/dtkSdk";
import { GetCookie, httpAddress, httpPhpHost } from "@/utils/utils";
import axios from "axios";
import { parse } from "qs";

/**
 * 配置request请求时的默认参数
 */
const hash = parse(`${window.location.hash.split("?")[1]}`);
const AppToken = GetCookie("AppToken");
const token = localStorage.getItem("token");

// if (token && token !== 'null' && token !=='undefined') {
//   axios.defaults.headers.common["token"] =token
// }

if (hash.token) {
  axios.defaults.headers.common["token"] = hash.token;
} else if (hash.AppToken || window.APPTOKEN || AppToken) {
  axios.defaults.headers.common["AppToken"] =
    hash.AppToken || window.APPTOKEN || AppToken;
}

const requestEvent = axios.create({
  // transformRequest:[errorHandler], // 默认错误处理
  maxContentLength: 5000,
});

// 添加响应前拦截器
requestEvent.interceptors.request.use(
  (response) => {
    if (response.url.includes("/cms-v2")) {
      response.headers = {
        ...response.headers,
        siteId: window.$mid,
      };
    }
    return response;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
requestEvent.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (response.status != 200 && Vue.prototype.$BIJsdk && process.env.NODE_ENV == "production") {
      Vue.prototype.$BIJsdk.onError({
        type:'apiError',
        method: response.type || "",
        desc: response.status,
        url: response.url || response.config.url || ""
      });
    }
    if (
      data.code != 1 &&
      data.code != "10001" &&
      data.code != "1000" &&
      data.code != "5500106" &&
      data.code != "5000705" &&
      data.msg &&
      data.msg != "" &&
      data.status != 0
    ) {
      // java接口逻辑判定

      if (window.layer) {
        window.layer.msg(data.msg);
      } else {
        // console.error(data.msg);
      }
      // debugger
      // return response;
    }
    return data;
  },
  function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
const dtkSdkOpen = new DtkSdk({
  appKey: "612bcfe884763",
  appSecret: "5dd20b08158402032845b45f5b67a349",
  checkSign: 1,
});

export default (url, { data, address,isNoMid, ...parmas }) => {
  const uid = GetCookie("jaw_uid");

  const jaw_uid =
    `${url}`.indexOf("dtk_go_app_api") > -1 && uid
      ? { app_token: uid }
      : uid && `${url}`.indexOf("taobaoapi") > -1
      ? { jaw_uid: uid }
      : `${url}`.indexOf("php-api") > -1
      ? { is_new_pc: 1 }
      : {};

  // isNoMid = true 不传mid
  const datas = { ...(data ? data : {}), ...jaw_uid ,...(`${url}`.indexOf("cms-v2") > -1 && !isNoMid ? {siteId: window.$mid} : {})};//window.$mid
  const urls =
    (address
      ? httpPhpHost()
      : `${url}`.indexOf("cms-v2") > -1
      ? process.env.NODE_ENV === "development" ||
        process.env.NODE_ENV === "stage"
        ? "https://cmscg.buydance.com"//https://cmscg.buydance.com
        : "https://cmscg.dataoke.com"
      : `${url}`.indexOf("http") > -1
      ? ""
      : `${url}`.indexOf("taobaoapi") > -1 ? process.env.NODE_ENV === "development" || process.env.NODE_ENV === "stage" ? "https://dtkapi.ffquan.cn" : "https://dtkapi.ffquan.cn"
      : httpAddress()) + url;

  if (parmas.method === "POST" && parmas.contentType == 'form') {
    let formData = new FormData();
    for (const key in datas) {
      if (datas.hasOwnProperty(key)) {
        const element = datas[key];
        formData.append(key, element)
      }
    }
    return axios({
      method: "post",
      url: urls,
      headers: {
        'content-type': 'application/x-www-form-urlencoded'
      },
      data: formData
    }).then(res=>{
      return res.data;
    })
  }
      
  // 开放平台接口 isOpenApi => true 开放平台接口必传
  if (parmas.isOpenApi) {
    // data.preSale = 1
    // const domain = 'https://openapi.dataoke.com'
    const domain = process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "stage" ? 'https://openapi.dataoke.com' : 'https://openapi.dataoke.com'
    return dtkSdkOpen.request(`${domain}${url}`, {
      ...parmas,
      ...{ form: data },
    });
  }

  return parmas.method === "POST"
    ? requestEvent.post(urls, datas)
    : requestEvent.get(urls, { ...{ params: datas }, ...parmas });
};
