import request from "@/utils/request";

//获取淘宝商品列表
export async function getTbDetails(params) {
    return request("/api/goods/get-goods-details", {
        method: "GET",
        isOpenApi: true,
        data: { ...params, version: "v1.2.3" },
    });
}

// 举报
export async function sendReports(params) {
    return request("/cms-v2/my-feedback", {
        method: 'POST',
        data: params
    })
}

// 获取猜你喜欢
export async function getLikeData(params) {
    return request("/api/goods/list-similer-goods-by-open", {
        method: "GET",
        isOpenApi: true,
        data: { ...params, version: "v1.2.2" },
    })
}

// 今日热销
export async function getTodayData(params) {
    return request("/api/goods/get-ranking-list", {
        method: "GET",
        isOpenApi: true,
        data: { ...params, version: "v1.3.0" },
    })
}

//https://yapi.buydance.com/project/98/interface/api/2940
// 转链
export async function transformLink(params) {
    return request(`/taobaoapi/get-privilege`, {
        method: 'GET',
        data: params
    })
}

// 获取京东详情
export async function getJdDetails(params) {
    return request(`/api/dels/jd/goods/get-details`, {
        method: "GET",
        requestType: "form",
        // isOpenApi => true 开放平台接口必传
        isOpenApi: true,
        data: { ...params, ...{ version: "v1.0.0" } }
    });
}

// jd转链
export async function getJDPromotionUnionConvert(params) {
    return request(`/dtk_go_cps_api/jd/tools-transform-chain`, {
        method: 'GET',
        data: params
    })
}

export async function getpddDetails(params) {
    return request(`/api/dels/pdd/goods/detail`, {
        method: "GET",
        requestType: "form",
        // isOpenApi => true 开放平台接口必传
        isOpenApi: true,
        data: { ...params, ...{ version: "v1.0.0" } }
    });
}

// 生成多多进宝推广链接
// https://ks42iw.yuque.com/ks42iw/wgots9/ykk1ac#0qA4h
export async function getPddOauthGoodsPromUrlGenerate(params) {
    return request(`/dtk_go_cps_api/pdd/oauth-goods-prom-url-generate`, {
        method: 'GET',
        data: params
    })
}

// 细分类目榜
export async function getHotSalesData(params) {
    return request("/api/subdivision/get-rank-list", {
        method: "GET",
        isOpenApi: true,
        data: { ...params, ...{ version: "v1.0.0" } }
    })
}

// 获取唯品会详情
//https://openapi.dataoke.com/api/vip/goods-detail
export async function getWphDetails(params) {
    return request(`/api/vip/goods-detail`, {
        method: "GET",
        requestType: "form",
        // isOpenApi => true 开放平台接口必传
        isOpenApi: true,
        data: { ...params, ...{ version: "v1.0.0" } }
    });
}

// wph转链
export async function getWphPromotionUnionConvert(params) {
    return request(`/api/vip/promote/link`, {
        method: 'GET',
        isOpenApi: true,
        data: { ...params, ...{ version: "v1.0.0" } }
    })
}

//https://yapi.buydance.com/project/98/interface/api/3068
// 快站短链创建
export async function createShort(params) {
    return request(`/taobaoapi/kz-create-short`, {
        method: 'GET',
        data: params
    })
}