<!--
 * @Author: your name
 * @Date: 2020-11-22 09:36:05
 * @LastEditTime: 2021-11-30 18:27:07
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/App.vue
-->
<template>
  <div id="app" :bi-route="$route.meta ? $route.meta.eventId || null : null">
    <headers />
    <!-- <keep-alive>
      <router-view v-if="isKeepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!isKeepAlive"></router-view> -->
    <div class="mian">
      <router-view></router-view>
    </div>
    <footers />
    <scroll-top v-if="isShowScrollTop"/>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Headers from "@/components/Header/index.vue";
import Footers from "@/components/Footer/index.vue";
import ScrollTop from "@/components/ScrollTop/index.vue";
import { isProjectUpdate } from 'isprojectupdate';
export default {
  name: "App",
  data() {
    return {
      loading:false,
      isShowScrollTop:true
    };
  },
  components: {
    ScrollTop,
    Headers,
    Footers
  },
  created(){
    //定时检测是否更新了项目
    if (process.env.NODE_ENV == "production") {
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') { // 状态判断：显示（切换到当前页面）
            isProjectUpdate('https://cmsstaticv2.ffquan.cn/pc/asset-manifest.json').then(res=>{
              if(res){
                window.location.reload(true)
              }
            });
          }
        })
    }
    this.handleServicesInit()
  },
  computed: {
    ...mapGetters(["userInfo", "marketGroup", "config"]),
    // isKeepAlive() {
    //   if (this.$route.meta.keepAlive === false) {
    //     return false;
    //   }
    //   if (this.$route.meta.keepAlive === undefined) {
    //     return true;
    //   }
    //   return this.$route.meta.keepAlive;
    // },
  },
  // mounted() {

  // },
  // beforeRouteEnter(to,from,next) {
  //   next((vm) => {
  //     console.log(vm.$rouote)
  //   })
  // },
  methods: {
    handleServicesInit() {
      const type360 = Math.round(Math.random() * (2 - 1)) + 1;
      sessionStorage.setItem("type360", type360)
      this.$services.getActivityList().then((res) => {

        if (res.code != 1 || !res.data ||  JSON.stringify(res.data) == '{}') {
          window.marketGroupConfig = [];
          return false;
        }
        window.marketGroupConfig =  res.data;
        this.$store.dispatch("saveMarketGroupConfig", res.data);
      });
      this.loading = false;
      this.$services.getCMSV2CmsConf({}).then((res) => {
        this.$store.dispatch("saveConfig", res.data);
        this.loading = true;
        // 搜索屏蔽词
        window.cmsSearchDangerous = `${res.data.shield_words}`.split(',');

      });
      this.$services
          .getCMSV2Ads({ ...{page: 1,is_pc:1}, ...(window.$tempId ? {temp_id: window.$tempId } : {}), type360})
          .then((res) => {
            if (res && res.code != 1) return false;
             const item = {
            ...res,
            ...{data:{...res.data,...{search_right:res.data.w_banners,notice_ad:res.data.w_banners,activity_nav:res.data.nav_ads}}}};

            this.$store.dispatch("saveMarketGroup", res.data);
          });
    },
    // handleShowScrollTop() {
    //   console.log(this.$route.path);
    //   if(this.$route.path == '/salesdemo') {
    //     this.isShowScrollTop = false
    //   }
    // }
  },
  watch: {
    config: function (state) {
      // document.title = document.title
      //   ? (document.title || "") + state.title
      //   : state.title;
      if (state.pc_icon) {
        // <link href="<?= $this->config->logo ?>" rel="icon">
        let iconDoc = document.createElement("link");
        iconDoc.href = state.pc_icon;
        iconDoc.rel = "icon";
        document.querySelectorAll("head")[0].appendChild(iconDoc);
      }
    },
    $route:function(state) {
      if(state.path === '/activity/ac'){
        this.isShowScrollTop = false
      }else {
        this.isShowScrollTop = true
      }
    }
  },
};
</script>

<style lang="less">
html,
body,
#app {
  font-family: "PingFang SC", Arial, -apple-system, BlinkMacSystemFont, Segoe UI,
    PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 12px;
  color: #757575;
  box-sizing: border-box;
  padding:0;
  margin:0;
}
button, input, keygen, legend, select, textarea {
    outline: 0;
}
ul,li,dl,dt,ol,p{
  margin:0; padding: 0; font-style: normal; list-style: none;
}
a{text-decoration:none; cursor: pointer;}
.mian{
  min-height: ~'calc(100vh - 335px)';
  background-color: #f6f6f6;
}

.market-pic{
  position: relative; z-index: 0;
  .market-tag{
    position: absolute; width: 100%;
    left:0;
    z-index: 10;
    bottom:0;
    background: no-repeat bottom left;
    background-size:100%  auto  ;

    display: flex;
    color: #fff;
    align-items:flex-end;
    height: 45px;
    line-height: 20px;
    font-size: 12px;
    box-sizing: border-box;
    padding-left: 75px;
    justify-content: flex-end;
    padding-right: 10px;
    &.market-small{
      background: bottom left no-repeat;
      background-size: auto 100%;
      width: 70px;
      height: 30px;
    }
    img{ width: 100%; border:0; display: block;}
  }
}
</style>
