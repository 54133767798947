import request from "@/utils/request";

//获取首页广告位
export async function getAds(type = 1) {
    return request("/cms-v2/ads", {
        method: "GET",
        data: {
            page: type,
            is_pc: 1,
            site_id:window.$mid
        }
    });
}

//获取自定义栏目商品id
/**
 * @param {id} params 栏目id 1=首页推荐商品 2=360商城0元秒伤 3=360商城热销排行
 * @returns 
 */
export async function getOperateColumn(params) {
    return request("/cms-v2/operate-column", {
        method: "GET",
        isNoMid: true,
        data: params
    });
}

//通过商品id获取商品
 export async function getSearchGoods(params) {
    return request("/open-api/get-dtk-search-goods", {
        method: "GET",
        isOpenApi: true,
        data: { ...params },
    });
}

//获取线报商品
export async function getlistTipOff(params) {
    return request("/api/dels/spider/list-tip-off", {
        method: "GET",
        isOpenApi: true,
        data: { ...params,version:"v4.0.0" },
    });
}

//获取今日必买列表
export async function getTodayList(params) {
    return request("/cms-v2/today-buy", {
        method: "GET",
        isNoMid:true
    });
}

//获取ddq列表
export async function getDdqList(params) {
    return request(`/api/category/ddq-goods-list`, {
        method: "GET",
        requestType: "form",
        // isOpenApi => true 开放平台接口必传
        isOpenApi: true,
        data: { ...params, ...{ version: "v1.2.1" } }
    });
}

//获取热销榜的分类
// 超级分类
export async function getClassify(params) {
    return request("/api/category/get-super-category", {
        method: "GET",
        // requestType: "form",
        data: { ...params, ...{ version: "v1.1.0" } },
        isOpenApi: true,
    });
}

// 实时销量榜
export async function getRankingList(params) {
    return request("/api/goods/get-ranking-list", {
        method: "GET",
        // requestType: "form",
        data: { ...params, ...{ version: "v1.3.0", pageSize: 9 } },
        isOpenApi: true,
    });
}

//九块九列表
export async function getNineList(params) {
    return request("/api/goods/nine/op-goods-list", {
        method: "GET",
        // requestType: "form",
        data: { ...params, ...{ version: "v2.0.0" , pageSize: 9} },
        isOpenApi: true,
    });
}

// 每日半价 时间
// export async function getInfoList(params) {
//     return request("/api/goods/get-half-price-day", {
//         method: "GET",
//         // requestType: "form",
//         data: { ...params, ...{ version: "v1.0.0" , pageSize: 9, page: 1} },
//         isOpenApi: true,
//     });
// }

export async function getInfoList(params) {
    return request("/api/goods/super-discount-goods", {
        method: "GET",
        // requestType: "form",
        data: { ...params, ...{ version: "v1.0.0" , pageSize: 9, page: 1} },
        isOpenApi: true,
    });
}

// 品牌精选列表
export async function getBrandList(params) {
    return request("/cms-v2/brand-list", {
        method: "GET",
        data: {page: 1, page_size: 10}
    });
}

// 好货精选 省钱信息
export async function getSaveMoneyInfo(params) {
    return request("/cms-v2/gmv", {
        method: "GET",
        data: {site_id:window.$mid}
    });
}

//获取商品列表
export async function getGoodsList(params) {
    return request("/api/goods/get-goods-list", {
      method: "GET",
      isOpenApi: true,
      data: { ...params, version: "v1.2.4" },
    });
  }

// 抖音热销/种草好物
export async function getRecommendGoodsList(params) {
    return request("/cms-v2/recommend-goods", {
        method: "GET",
        isNoMid: true
    });
}
