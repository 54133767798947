var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isShowAct &&
      _vm.isAct &&
      _vm.marketGroup.pop_banner &&
      _vm.marketGroup.pop_banner.length > 0
        ? _c("div", { staticClass: "act-pop" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.pop_banner[0],
                        expression: "marketGroup.pop_banner[0]"
                      }
                    ],
                    attrs: {
                      target: "_blank",
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.pop_banner[0].title,
                      "data-url": _vm.marketGroup.pop_banner[0].url,
                      "data-weizhi": "pop_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.pop_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  { staticClass: "close", on: { click: _vm.handleHidePopAct } },
                  [_c("icon-font", { attrs: { type: "icon-hide" } })],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.isShowActFloat &&
      _vm.isAct &&
      _vm.marketGroup.bottom_banner &&
      _vm.marketGroup.float_banner.length > 0
        ? _c("div", { staticClass: "act-float" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.float_banner[0],
                        expression: "marketGroup.float_banner[0]"
                      }
                    ],
                    attrs: {
                      target: "_blank",
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.float_banner[0].title,
                      "data-url": _vm.marketGroup.float_banner[0].url,
                      "data-weizhi": "float_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.float_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.handleHideFloatAct }
                  },
                  [_c("icon-font", { attrs: { type: "icon-hide" } })],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _vm.isShowActBottom &&
      _vm.isAct &&
      _vm.marketGroup.bottom_banner &&
      _vm.marketGroup.bottom_banner.length > 0
        ? _c("div", { staticClass: "act-bottom" }, [
            _c(
              "div",
              { staticClass: "pic", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.bottom_banner[0],
                        expression: "marketGroup.bottom_banner[0]"
                      }
                    ],
                    attrs: {
                      target: "_blank",
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.bottom_banner[0].title,
                      "data-url": _vm.marketGroup.bottom_banner[0].url,
                      "data-weizhi": "bottom_banner"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.bottom_banner[0].img }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass: "close",
                    on: { click: _vm.handleHideBottomAct }
                  },
                  [_c("icon-font", { attrs: { type: "icon-hide" } })],
                  1
                )
              ]
            )
          ])
        : _vm._e(),
      _c("ActUserModal"),
      _c("div", { staticClass: "scroll-top" }, [
        _c("ul", { staticClass: "float-tool" }, [
          _c("li", { on: { click: _vm.handleAddPanel } }, [
            _c("img", {
              attrs: {
                src:
                  "https://cmsstaticnew.dataoke.com//images/home/sc.png?v=202111051520",
                alt: ""
              }
            }),
            _c("p", [_vm._v("收藏")]),
            _vm.showSCModal
              ? _c("img", {
                  staticClass: "sc_modal",
                  attrs: { src: require("./images/sc_modal.png"), alt: "" }
                })
              : _vm._e()
          ]),
          _c("li", { staticClass: "ft-share" }, [
            _c("img", {
              attrs: {
                src:
                  "https://cmsstaticnew.dataoke.com//images/home/share.png?v=202111051520",
                alt: ""
              }
            }),
            _c("p", [_vm._v("分享")]),
            _c("div", { staticClass: "share-platform" }, [
              _c("div", { staticClass: "share-platform-r" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "bshare-custom bdsharebuttonbox bdshare-button-style1-32"
                  },
                  [
                    _c("a", {
                      staticClass: "bshare-weixin bds_weixin",
                      attrs: { title: "分享到微信" },
                      on: {
                        click: function($event) {
                          return _vm.handleShare("weixin")
                        }
                      }
                    }),
                    _c("a", {
                      staticClass: "custom_share bds_tsina",
                      staticStyle: { display: "inline-block" },
                      attrs: { title: "分享到新浪微博" },
                      on: {
                        click: function($event) {
                          return _vm.handleShare("weibo")
                        }
                      }
                    }),
                    _c("a", {
                      staticClass: "bshare-qqim bds_sqq",
                      attrs: { title: "分享到QQ好友" },
                      on: {
                        click: function($event) {
                          return _vm.handleShare("qq")
                        }
                      }
                    }),
                    _c("a", {
                      staticClass: "custom_share bds_qzone",
                      staticStyle: { display: "inline-block" },
                      attrs: { title: "分享到QQ空间" },
                      on: {
                        click: function($event) {
                          return _vm.handleShare("qqkj")
                        }
                      }
                    })
                  ]
                )
              ])
            ])
          ]),
          _vm.config.qq
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: {
                      target: "_blank",
                      href:
                        "http://wpa.qq.com/msgrd?v=3&uin=" +
                        _vm.config.qq +
                        "&site=qq&menu=yes"
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "https://cmsstaticnew.dataoke.com//images/home/kf.png?v=202111051520",
                        alt: ""
                      }
                    }),
                    _c("p", [_vm._v("客服")])
                  ]
                )
              ])
            : _vm._e(),
          _vm._m(0),
          _c(
            "li",
            {
              class: "zsMarket_wrap" + (_vm.showZSMarket ? "_act" : ""),
              on: { click: _vm.handleShowZSMarket }
            },
            [
              _vm._m(1),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showZSMarket,
                      expression: "showZSMarket"
                    }
                  ],
                  staticClass: "modal_container"
                },
                [
                  _c("div", { staticClass: "code", attrs: { id: "zsQrcode" } }),
                  _vm._m(2)
                ]
              )
            ]
          ),
          _c(
            "li",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showReturnTop,
                  expression: "showReturnTop"
                }
              ],
              staticClass: "to_top",
              on: {
                click: function($event) {
                  return _vm.handleToTop()
                }
              }
            },
            [
              _c("img", {
                staticStyle: { "padding-top": "12px" },
                attrs: {
                  src:
                    "https://cmsstaticnew.dataoke.com//images/home/top.png?v=202111051520",
                  alt: ""
                }
              })
            ]
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showQRCode,
                expression: "showQRCode"
              }
            ],
            staticClass: "QRCode",
            on: { click: _vm.handleQrcode }
          },
          [_vm._m(3)]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { attrs: { href: "?r=index/feedbackpc", target: "_blank" } }, [
        _c("img", {
          attrs: {
            src:
              "https://cmsstaticnew.dataoke.com//images/home/feedback.png?v=202111051520",
            alt: ""
          }
        }),
        _c("p", [_vm._v("反馈")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("a", [_c("p", [_vm._v("掌上")]), _c("p", [_vm._v("商城")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tip" }, [
      _vm._v("打开手机扫一扫 "),
      _c("br"),
      _vm._v(" 随时随地在线淘")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "code", attrs: { id: "scrollQrcode" } }),
      _vm._v(" 扫码分享到-微信 ")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }