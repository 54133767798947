<template>
  <div :style="`opacity:${config.site_id ? 1 : 0};`">
    <ScrollTop360 v-if="config.pc_template_id == 5" />
    <ScrollTopDefault v-if="config.pc_template_id != 5" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import ScrollTop360 from './temp/360/index.vue';
import ScrollTopDefault from './temp/default/index.vue';
export default {
  data() {
    return {
    };
  },
  name: "header",
  components: {
    ScrollTop360: () => import("./temp/360/index.vue"),
    ScrollTopDefault
  },
  computed: {
    ...mapGetters(["marketGroup", "config"]),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
