// import { parse, stringify } from "qs";

export const httpAddress = () => {

  return process.NODE_ENV === "development" ||
    window.location.href.indexOf("buydance") > -1 || window.location.href.indexOf("localhost")>0
    ? "//dtkapi.buydance.com"
    : "//dtkapi.ffquan.cn";
  // return '//dtkapi.ffquan.cn';//'//dtkapi.ffquan.cn'
};
export const httpPhpHost = () => {
  const domain = window.document.domain;
  return `${window.location.origin}`;
};
export const publicStatic = () => {
  const domain = window.document.domain;
  if (domain.indexOf("local") > -1 || domain.indexOf("127.0.0.1") > -1) {
    return "//public.buydance.com";
  } else if (domain.indexOf("buydance") > -1) {
    return "//public.buydance.com";
  }
  // taobaoapi.buydance.com
  //
  return "//public.ffquan.cn";
};

// 新开放平台api 域名
export const httpOpenApiv2 = (process.NODE_ENV === "development" || window.location.href.indexOf("buydance") > -1 || window.location.href.indexOf("localhost")>0
? "https://openapiv2.dataoke.com" || "https://openapiv2.buydance.com"
: "https://openapiv2.dataoke.com");

// 当前浏览器域名截取
export const getDomain = () => {
  const DomainRegular = [
    /(.+\.)?(([^.]+)\.(com.cn|net.cn|org.cn|gov.cn))/,
    /(.+\.)?(([^.]+)\.([^.]+))/,
  ];
  const domain = window.location.host;
  if (domain.indexOf("localhost") >= 0) {
    return "localhost";
  }
  if (domain.indexOf("127.0.0.1") >= 0) {
    return "127.0.0.1";
  }
  const ret = DomainRegular.map((r) => domain.match(r)).filter(
    (r) => r !== null
  )[0];
  return ret[2];
};

//设置cookie
export const SetCookie = (c_name, value, time) => {
  const domain = getDomain();
  var d = new Date();
  d.setHours(d.getHours() + (time || 24 * 30));
  document.cookie =
    c_name +
    "=" +
    escape(value) +
    (";expires=" + d.toGMTString()) +
    `;domain=${domain};path=/`;
};

// 获取cookie
export const GetCookie = (c_name) => {
  var c_start, c_end;
  if (document.cookie.length > 0) {
    c_start = document.cookie.indexOf(c_name + "=");
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1;
      c_end = document.cookie.indexOf(";", c_start);
      if (c_end === -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return false;
};

// 销量
export const DigitalAbbNumber = ({ num = 0, text = "万" }) => {
  let nums = parseFloat(num);
  if ((nums + "").indexOf(text) >= 0) {
    return nums;
  }
  if (nums < 10000 && nums >= 1000) {
    // `${parseFloat((nums / 1000).toFixed(1))}k`
    return nums;
  } else if (nums < 1000) {
    return nums;
  }
  var result = [],
    counter = 0;
  nums = (nums || 0).toString().split("");
  var isfor = false;
  for (var i = nums.length - 1; i >= 0; i--) {
    counter++;

    if (isfor) {
      result.unshift(nums[i]);
    }
    if (!(counter % 4) && i != 0 && !isfor) {
      result.unshift(nums[i]);
      result.unshift(".");
      isfor = true;
    }
  }

  nums = parseFloat(result.join(""));
  return parseInt(nums) < 10000
    ? `${nums}${text}`
    : parseInt(nums) == 0
    ? `${parseFloat((nums * 10).toFixed(1))}k`
    : `${parseFloat((nums / 10000).toFixed(1))}亿`;
};

// const isImportCDN = [];
export const importCDN = (url, name) => {
  if (!window.isImportCDN) {
    window.isImportCDN = [];
  }
  return new Promise((resolve) => {
    if (
      window.isImportCDN.indexOf(url) > -1 ||
      (`${url}`.indexOf("jquery") > -1 && window.$)
    ) {
      resolve(window[name]);
      return false;
    }
    // var script =  document.createElement('script');
    // script.src = res[reload.js[i]];
    // document.querySelectorAll('body')[0].appendChild(script);

    const dom = document.createElement("script");
    dom.src =
      `${url}`.indexOf("//") >= 0
        ? `${url}?v=20200603`
        : `${publicStatic()}${url}?v=20200603`;
    dom.type = "text/javascript";
    dom.charset = "utf-8";
    dom.onload = () => {
      window.isImportCDN = [...window.isImportCDN, ...[url]];
      setTimeout(() => {
        resolve(window[name]);
      }, 100);
    };
    document.querySelectorAll("body")[0].appendChild(dom);
    // document.head.appendChild(dom);
  });
};

export function javaApiUrl(val) {
  //根据运行环境自动切换接口调用地址
  console.log("java!!!!");

  const dev = process.env.NODE_ENV === "development";
  const defaultHost =
    window.location.hostname.indexOf("buydance") >= 0 ||
    window.location.hostname.indexOf("127.0.0.1") >= 0
      ? "//cmspoints.buydance.com"
      : "//cmspoints.dataoke.com";
  const currentHost = () => {
    if (dev || /^[\d]|localhost/.test(window.location.host)) return defaultHost;
    return `${defaultHost}`;
  };
  console.log("javaApiUrl: ", `${currentHost()}${val || ""}`);
  return `${currentHost()}${val || ""}`;
}

// 随机生成手机缩略号
export const MobileMathFloor = () => {
  const items = [
    "133",
    "149",
    "153",
    "173",
    "177",
    "180",
    "181",
    "189",
    "199",
    "130",
    "131",
    "132",
    "145",
    "155",
    "156",
    "166",
    "171",
    "175",
    "176",
    "185",
    "186",
    "166",
    "134",
    "135",
    "136",
    "137",
    "138",
    "139",
    "147",
    "150",
    "151",
    "152",
    "157",
    "158",
    "159",
    "172",
    "178",
    "182",
    "183",
    "184",
    "187",
    "188",
    "198",
  ];
  const random = (min, max) => {
    const num = Math.floor(Math.random() * (max - min)) + min;
    return num < 10
      ? `000${num}`
      : num < 100
      ? `00${num}`
      : num < 1000
      ? `0${num}`
      : num;
  };
  return `${items[parseInt(items.length * Math.random())]}****${random(
    0,
    9999
  )}`;
};

// 跨域图片转base64
export const GetImageBase64 = (items = []) => {
  // debugger
  return new Promise((resolve, reject) => {
    let key = 0;
    let ret = [];
    // 把淘宝图片转换成base64
    var getToolsGetImg = function(index, url) {
      // debugger
      // ret[index] = false;
      if (!window.ImageBase64) window.ImageBase64 = [];
      if (window.ImageBase64 && window.ImageBase64[url]) {
        ret[index] = window.ImageBase64[url];
        // debugger
        getToolsGetImg(index + 1, items[index + 1]);
        return false;
      } else if (index < items.length) {
        main(url, (res) => {
          window.ImageBase64[url] = res;
          ret[index] = res;
          getToolsGetImg(index + 1, items[index + 1]);
        });
      } else {
        resolve(ret);
      }
    };

    const getBase64Image = (img) => {
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, img.width, img.height);
      var dataURL = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
      return dataURL;
    };

    const isHttps =
      window.location.href.indexOf("https") > -1 &&
      window.location.href.indexOf("local") < 0;

    const main = (url, cb) => {
      const xhr = new XMLHttpRequest();
      // https网站 需要将转换base64图片专成https图片

      xhr.onload = function() {
        if (xhr.readyState == 4 && parseInt(this.status, 10) === 200) {
          const img = new Image();
          img.src = URL.createObjectURL(this.response);
          img.onload = function() {
            cb &&
              cb({
                width: img.width,
                height: img.height,
                pic: getBase64Image(img),
              });
          };
        } else {
          cb({ width: 0, height: 0, pic: url });
          reject();
        }
      };
      xhr.open("GET", isHttps ? url.replace("http://", "https://") : url, true);
      xhr.responseType = "blob";
      xhr.send(null);
    };

    getToolsGetImg(key, items[key]);
  });
};

export const AccAdd = ({ num }) => {
  const a = num[0];
  const b = num[1];
  let c, d, m;
  try {
    c = a.toString().split(".")[1].length;
  } catch (e) {
    c = 0;
  }
  try {
    d = b.toString().split(".")[1].length;
  } catch (e) {
    d = 0;
  }
  m = Math.pow(10, Math.max(c, d));
  return (a * m + b * m) / m;
};

// 浮点数计算减法
export const AccSub = ({ num }) => {
  var r1, r2, m, n;
  try {
    r1 = num[0].toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = num[1].toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return Number(((num[0] * m - num[1] * m) / m).toFixed(n));
};

//除法函数，用来得到精确的除法结果

export const AccDiv = ({ num }) => {
  if (!num[0]) return 0;
  var t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = num[0].toString().split(".")[1].length;
  } catch (e) {}
  try {
    t2 = num[1].toString().split(".")[1].length;
  } catch (e) {}
  r1 = Number(num[0].toString().replace(".", ""));
  r2 = Number(num[1].toString().replace(".", ""));
  return (r1 / r2) * Math.pow(10, t2 - t1);
};

//乘法函数
export const AccMul = ({ num }) => {
  var m = 0,
    s1 = num[0].toString(),
    s2 = num[1].toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
};

// webp
export const isWebp = (() => {
  try {
    return document
      .createElement("canvas")
      .toDataURL("image/webp")
      .indexOf("data:image/webp") == 0
      ? "_.webp"
      : "";
  } catch (err) {
    return "";
  }
})();

// base64图片压缩
export const Base64Pic = (base64, bili) => {
  //处理缩放，转格式
  return new Promise((resolve) => {
    let _img = new Image();
    _img.src = base64;
    _img.onload = function() {
      let _canvas = document.createElement("canvas");
      let w = this.width / bili;
      let h = this.height / bili;
      _canvas.setAttribute("width", w);
      _canvas.setAttribute("height", h);
      _canvas.getContext("2d").drawImage(this, 0, 0, w, h);
      let base64s = _canvas.toDataURL("image/jpeg");
      _canvas.toBlob(function(blob) {
        console.log(blob.size);
        resolve(base64s);
      }, "image/jpeg");
    };
  });
};

export const getAppVersion = () => {
  let ua = navigator.userAgent.split(" ");
  const result = {};
  ua.map((item) => {
    let parts = item.split("/");
    result[parts.shift()] = parts.shift();
  });
  return result.dtkapp ? parseFloat(result.dtkapp.replace(/\./g, "")) : "";
};

//生成uuid
export function guid() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

// 异步获取func里的返回值，直到timeout(毫秒)
export function asyncGetData(dataFunc, timeout) {
  return new Promise((resolve, reject) => {
    let total = 0;
    const delay = 100;
    let intervalObj = setInterval(() => {
      total += delay;
      if (total > timeout) {
        clearInterval(intervalObj);
        reject('获取 data 失败');
        return;
      }
      const data = dataFunc();
      if (data) {
        clearInterval(intervalObj);
        resolve(data);
      }
    }, delay);
  });
}