/*
 * @Author: your name
 * @Date: 2021-11-08 10:15:34
 * @LastEditTime: 2021-11-08 11:21:47
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AEi
 * @FilePath: /www/dtk_vue_cms_server_pc_v2/src/services/index.js
 */
export * from "./module/search";
export * from "./module/userInfo";
export * from "./module/detail";
export * from "./module/home";

