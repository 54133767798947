/*
 * @Author: your name
 * @Date: 2020-09-09 14:17:28
 * @LastEditTime: 2021-10-12 16:12:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/demo-ui/vue-cli/demo5/src/store/mutation-types.js
 */
export const USER_INFO = 'USER_INFO'; // 用户信息
export const CLEAR_USER_INFO = 'CLEAR_USER_INFO'; // 用户信息
export const MAEKET_GEOUP = 'MAEKET_GEOUP'; // 广告位配置
export const CONFIG = 'CONFIG';// 全局配置
export const FOOTER_TABBAR = 'footerTabbar';// 底部导航

export const MARKET_GROUP_CONGIG = 'marketGroupConfig'; // 商城 活动物料配置 
