import { getSearchKwDangerousProhibition } from '@/utils/config';
import request from "@/utils/request";
import { httpOpenApiv2 } from "@/utils/utils";

//获取淘宝商品列表
//https://yapi.buydance.com/project/92/interface/api/5576
export async function getTbGoods(params) {
  if(params.keyWords && getSearchKwDangerousProhibition().indexOf(decodeURIComponent(encodeURIComponent(params.keyWords)))>-1){
    return new Promise((resolve,reject)=>{
      resolve({"requestId":"3ffe72e2a68da500f220b7f4b78ee6fe","time":1640942441615,"code":0,"msg":"成功","data":{"list":[]}});
    })
  }
  return request(httpOpenApiv2 + "/open-api/goods/list-super-goods", {
    method: "GET",
    data: { ...params, appKey: "622b674f76e31",isCms:1 },
  });
}

//获取分类结果
export async function getSuperCategory(params) {
  return request("/api/category/get-super-category", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v1.1.0" },
  });
}

//获取商品列表
export async function getGoodsList(params) {
  return request("/api/goods/get-goods-list", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v1.2.4" },
  });
}

//获取相似商品
export async function getGoodsByOpen(params) {
  return request("/api/goods/list-similer-goods-by-open", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v1.2.2" },
  });
}

//获取商品详情
export async function getGoodsDetail(params) {
  return request("/api/goods/get-goods-details", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v1.2.3" },
  });
}


//京东商品
export async function getJdGoods(params) {
  if(params.keyword && getSearchKwDangerousProhibition().indexOf(decodeURIComponent(encodeURIComponent(params.keyword)))>-1){
    return new Promise((resolve,reject)=>{
      resolve({"requestId":"9af9370039ce168fecbeb2d0121014fc","time":1640943312372,"code":0,"msg":"成功","data":{"list":[],"totalNum":0,"pageId":""}});
    })
  }
  return request("/api/dels/jd/goods/search", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v1.0.0" },
  });
}

//拼多多商品
export async function getPddGoods(params) {

  if(params.keyword&&getSearchKwDangerousProhibition().indexOf(decodeURIComponent(encodeURIComponent(params.keyword)))>-1){
    return new Promise((resolve,reject)=>{
      resolve({"requestId":"72e661b4db2010c5174156722eb20dbe","time":1640943360232,"code":0,"msg":"成功","data":{"goodsList":[],"listId":"","searchId":"","totalCount":0}});
    })
  }
  return request("/api/dels/pdd/goods/cms/search", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v1.0.0" },
  });
}

//拼多多搜索开放平台
//https://www.dataoke.com/pmc/api-d.html?id=71
export async function getPddGoodsOpen(params) {
  if(params.keyword&&getSearchKwDangerousProhibition().indexOf(decodeURIComponent(encodeURIComponent(params.keyword)))>-1){
    return new Promise((resolve,reject)=>{
      resolve({"requestId":"72e661b4db2010c5174156722eb20dbe","time":1640943360232,"code":0,"msg":"成功","data":{"goodsList":[],"listId":"","searchId":"","totalCount":0}});
    })
  }
  return request("/api/dels/pdd/goods/search", {
    method: "GET",
    isOpenApi: true,
    data: { ...params, version: "v2.0.0", appKey:'612bcfe884763' },
  });
}

// 唯品会联盟搜索
// https://www.dataoke.com/pmc/api-d.html?id=99
export async function getOpenApiListSuperGoods(params) {
  return request(httpOpenApiv2 + "/open-api/vip/search-by-keywords?appKey=622b674f76e31", {
    method: "GET",
    contentType: "form",
    data: { ...params },
  });
}

// 唯品会商品转链
// https://www.dataoke.com/kfpt/api-d.html?id=98
// return request("/taobaoapi/get-vip-url", {
//   method: "POST",
//   isWph: true,
//   isOpenApi: true,
//   isLink: true,
//   data: { ...params },
// });
// https://openapi.dataoke.com/api/vip/promote/link
export async function getVipLink(params) {
  return request("/taobaoapi/get-vip-url?appKey=622b674f76e31", {
    method: "POST",
    contentType: "form",
    data: { ...params },
  });
}
