var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("i", {
    class: "iconfont " + _vm.type + " " + (_vm.className ? _vm.className : ""),
    style: "" + (_vm.styles ? _vm.styles : ""),
    on: { click: _vm.handleClick }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }