/*
 * @Author: your name
 * @Date: 2020-09-09 14:17:28
 * @LastEditTime: 2021-10-12 15:32:44
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/demo-ui/vue-cli/demo5/src/store/actions.js
 */
import {
    CLEAR_USER_INFO, CONFIG,
    FOOTER_TABBAR, MAEKET_GEOUP, USER_INFO,MARKET_GROUP_CONGIG
} from './mutation-types'

export const actions = {
    saveUserInfo: ({commit}, info) => commit(USER_INFO, info),
    clearUserInfo: ({commit}, info) => commit(CLEAR_USER_INFO, info),
    saveMarketGroup: ({commit}, info) => commit(MAEKET_GEOUP, info),
    saveConfig: ({commit}, info) => commit(CONFIG, info),
    saveFooterTabbar: ({commit}, info) => commit(FOOTER_TABBAR, info),
    saveMarketGroupConfig: ({commit}, info) => commit(MARKET_GROUP_CONGIG, info),
}
