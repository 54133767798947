import { GetCookie } from "@/utils/utils";
// import Vue from "vue";
// import VueRouter from "vue-router";
Vue.use(VueRouter);

/*
 *  新增路由规则 query路由新增加规则：
 *  1.路由名称中不能携带大写字母
 *  2.前端新增路由 对应 dtk_php_cms_server_new 项目中也需要创建相同的路由
 *  3.不能以0-9数字开头
 */

const routes = [
  {
    path: "/",
    component: () => import("../pages/home/index.vue"),
    meta: {
      eventId: "shouye",
    },
  },
  {
    path: "/index/index",
    component: () => import("../pages/home/index.vue"),
    meta: {
      eventId: "shouye",
    },
  },
  {
    path: "/l/d",
    name: "taobao",
    component: () => import("../pages/detail/tb.vue"),
    meta: {
      eventId: 'detail'
    }
  },
  {
    path: "/l/jd",
    name: "jd",
    component: () => import("../pages/detail/jd.vue"),
    meta: {
      eventId: 'detail'
    }
  },
  {
    path: "/l/dd",
    name: "pdd",
    component: () => import("../pages/detail/pdd.vue"),
    meta: {
      eventId: 'detail'
    }
  },
  {
    path: "/l/wph",
    name: "wph",
    component: () => import("../pages/detail/wph.vue"),
    meta: {
      eventId: 'detail'
    }
  },
  {
    path: "/index/feedbackpc",
    name: "feedbackpc",
    component: () => import("../pages/feedbackpc/index.vue"),
  },
  {
    path: "/p",
    component: () => import("../pages/berserk/index.vue"),
    meta: {
      eventId: "bang",
    },
  },
  {
    path: "/every/half",
    component: () => import("../pages/halfProceDaily/index.vue"),
    meta: {
      eventId: "banjia",
    },
  },
  {
    path: "/nine",
    component: () => import("../pages/nine/index.vue"),
    meta: {
      eventId: "9kuai9",
    },
  },
  {
    path: "/l",
    component: () => import("../pages/search/tb/index.vue"),
    meta: {
      eventId: "haohuo",
    },
  },
  {
    path: "/l/jdlist",
    component: () => import("../pages/search/jd/index.vue"),
    meta: {
      eventId: "haohuo",
    },
  },
  {
    path: "/l/ddlist",
    component: () => import("../pages/search/pdd/index.vue"),
    meta: {
      eventId: "haohuo",
    },
  },

  {
    path: "/l/wphlist",
    component: () => import("../pages/search/wph/index.vue"),
    meta: {
      eventId: "haohuo",
    },
  },
  {
    path: "/ddq",
    component: () => import("../pages/ddq/index.vue"),
    meta: {
      eventId: "dongdong",
    },
  },
  {
    path: "/realtime/zsz",
    component: () => import("../pages/zsz/index.vue"),
    meta: {
      eventId: "zhe",
    },
  },
  {
    path: "/activity/ac",
    component: () => import("../pages/column/index.vue"),
  },
  // 商品失效页面
  {
    path: "/index/notfound",
    component: () => import("../pages/notfound/index.vue"),
  },
  //零元购页面
  {
    path: "/zero",
    component: () => import("../pages/zero/index.vue"),
    meta: {
      eventId: "zero",
    },
  },
  {
    path: "/notfound/index",
    component: () => import("../pages/notfound/nosites.vue"),
    meta: {
      eventId: "zero",
    },
  },
];

const router = new VueRouter({
  mode: "query",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = router.push;

router.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const originalReplace = router.replace;
router.replace = function push(location) {
  return originalReplace.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  let islogin = GetCookie("AppToken");
  if (to.path == "/stationMaster/login") {
    if (islogin) {
      return next("/stationMaster");
    } else {
      return next();
    }
  }
  next();
});

export default router;
