var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: "opacity:" + (_vm.config.site_id ? 1 : 0) + ";" },
    [
      _vm.config.pc_template_id == 5 ? _c("Hearder360") : _vm._e(),
      _vm.config.pc_template_id != 5 ? _c("HearderDefault") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }