/*
 * @Author: your name
 * @Date: 2020-09-09 14:17:28
 * @LastEditTime: 2021-11-30 14:36:36
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/demo-ui/vue-cli/demo5/src/store/mutations.js
 */
import {
    CLEAR_USER_INFO, CONFIG,
    FOOTER_TABBAR, MAEKET_GEOUP, MARKET_GROUP_CONGIG, USER_INFO
} from './mutation-types';

export const mutations = {
    [USER_INFO] (state, info) {
        state.userInfo = {...state.userInfo,...info}
    },
    [CLEAR_USER_INFO] (state, info) {
        state.userInfo = {}
    },
    [MAEKET_GEOUP] (state, info) {
        state.marketGroup = {...state.marketGroup,...info}
    },
    [CONFIG] (state, info) {
        state.config = {...state.config,...info}
        localStorage.setItem('site_id', info.site_id || '1')
    },
    [FOOTER_TABBAR] (state, info) {
        state[FOOTER_TABBAR] = {...state[FOOTER_TABBAR],...info}
    },
    [MARKET_GROUP_CONGIG] (state, info) {
        // window[MARKET_GROUP_CONGIG] = {...state[MARKET_GROUP_CONGIG],...info};
        state[MARKET_GROUP_CONGIG] = {...state[MARKET_GROUP_CONGIG],...info}
    }
}
