var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isNavActive(_vm.nav[0]) &&
    _vm.isShowAct &&
    _vm.marketGroup.top_banner &&
    _vm.marketGroup.top_banner.length > 0
      ? _c(
          "div",
          { staticClass: "act_top", attrs: { "bi-route": "chuchuang" } },
          [
            _c(
              "span",
              { staticClass: "close", on: { click: _vm.handleHideAct } },
              [_c("icon-font", { attrs: { type: "icon-hide" } })],
              1
            ),
            _c(
              "a",
              {
                directives: [
                  {
                    name: "jump",
                    rawName: "v-jump",
                    value: _vm.marketGroup.top_banner[0],
                    expression: "marketGroup.top_banner[0]"
                  }
                ],
                attrs: {
                  target: "_blank",
                  "data-weizhi": "top_banner",
                  "data-url": _vm.marketGroup.top_banner[0].url,
                  "data-title": _vm.marketGroup.top_banner[0].title,
                  "bi-click": "go"
                }
              },
              [_c("img", { attrs: { src: _vm.marketGroup.top_banner[0].img } })]
            )
          ]
        )
      : _vm._e(),
    _c("header", { staticClass: "header" }, [
      _c("div", { staticClass: "header_search" }, [
        _vm.config.logo
          ? _c(
              "a",
              { staticClass: "logo", attrs: { href: "?r=index/index" } },
              [
                _c("img", {
                  attrs: {
                    alt: _vm.config.siteName || _vm.config.site_name,
                    src: _vm.config.logo
                  }
                })
              ]
            )
          : _vm._e(),
        !_vm.config.logo
          ? _c(
              "a",
              { staticClass: "logo", attrs: { href: "?r=index/index" } },
              [_vm._v(_vm._s(_vm.config.siteName || _vm.config.site_name))]
            )
          : _vm._e(),
        _c("div", { staticClass: "search", attrs: { "bi-route": "search" } }, [
          _c(
            "div",
            { staticClass: "tag" },
            _vm._l(_vm.tag, function(item, key) {
              return item.isShow
                ? _c(
                    "a",
                    {
                      key: key,
                      class: { active: item.type == _vm.tagIndex },
                      attrs: {
                        "bi-click": _vm.tabs,
                        "data-tabs": item.eventId || ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.handleTagType(item)
                        }
                      }
                    },
                    [
                      _c("img", { attrs: { src: item.icon } }),
                      _vm._v(_vm._s(item.name))
                    ]
                  )
                : _vm._e()
            }),
            0
          ),
          _c(
            "div",
            { class: "search-form " + (_vm.tagIndex != "1" ? "br" : "") },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.kw,
                    expression: "kw"
                  }
                ],
                ref: "inputDom",
                attrs: { maxlength: "100", placeholder: _vm.placeholder },
                domProps: { value: _vm.kw },
                on: {
                  keyup: _vm.handleSearchKeyup,
                  focus: _vm.handleFocus,
                  blur: _vm.handleBlur,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.kw = $event.target.value
                  }
                }
              }),
              _c(
                "a",
                { staticClass: "close", on: { click: _vm.handleClose } },
                [
                  _c("icon-font", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.kw,
                        expression: "kw"
                      }
                    ],
                    attrs: { type: "icon-close" }
                  })
                ],
                1
              ),
              _c(
                "button",
                {
                  attrs: {
                    "bi-click": "searchbt",
                    "data-tabs": _vm.curTagName,
                    "data-bi360": _vm.bi360,
                    "data-kw": _vm.kw
                      ? decodeURIComponent(encodeURIComponent(_vm.kw))
                      : ""
                  },
                  on: { click: _vm.handleSearch }
                },
                [_vm._v(" 搜隐藏券 ")]
              ),
              _vm.searchTag.length > 0
                ? _c(
                    "ul",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isInputFocus,
                          expression: "isInputFocus"
                        }
                      ],
                      staticClass: "kw-list"
                    },
                    _vm._l(_vm.searchTag, function(item) {
                      return _c(
                        "li",
                        {
                          key: item,
                          class: { active: item.kw == _vm.kw },
                          on: {
                            click: function($event) {
                              return _vm.handleSearchKw(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.kw) + " ")]
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          ),
          _c(
            "div",
            { staticClass: "search-list" },
            _vm._l(_vm.tagList || [], function(item, index) {
              return _c(
                "a",
                {
                  key: item,
                  attrs: {
                    "bi-click": "tag",
                    "data-index": index,
                    "data-kw": item,
                    "data-tabs": _vm.curTagName
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleSearch({ kw: item })
                    }
                  }
                },
                [_vm._v(_vm._s(item))]
              )
            }),
            0
          )
        ]),
        _vm.marketGroup &&
        _vm.marketGroup.search_right &&
        _vm.marketGroup.search_right[0]
          ? _c(
              "div",
              { staticClass: "act", attrs: { "bi-route": "chuchuang" } },
              [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "jump",
                        rawName: "v-jump",
                        value: _vm.marketGroup.search_right[0],
                        expression: "marketGroup.search_right[0]"
                      }
                    ],
                    attrs: {
                      "bi-click": "go",
                      "data-title": _vm.marketGroup.search_right[0].title,
                      "data-url": _vm.marketGroup.search_right[0].url,
                      "data-weizhi": "search_right"
                    }
                  },
                  [
                    _c("img", {
                      attrs: { src: _vm.marketGroup.search_right[0].img }
                    })
                  ]
                )
              ]
            )
          : _vm._e()
      ]),
      _c("nav", { staticClass: "nav" }, [
        _c(
          "ul",
          [
            _vm._l(_vm.nav, function(item, key) {
              return _c(
                "li",
                {
                  key: key,
                  class: {
                    active: _vm.isNavActive(item),
                    "nav-pic": item.type == "act"
                  },
                  attrs: {
                    "bi-route": item.type == "act" ? _vm.chuchuang : _vm.daohang
                  }
                },
                [
                  item.isNew
                    ? _c("i", { staticClass: "new" }, [_vm._v("NEW")])
                    : _vm._e(),
                  item.type == "act"
                    ? _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "jump",
                              rawName: "v-jump",
                              value: item,
                              expression: "item"
                            }
                          ],
                          attrs: {
                            "bi-click": "go",
                            "data-title": _vm.marketGroup.activity_nav[0].title,
                            "data-url": _vm.marketGroup.activity_nav[0].url,
                            "data-weizhi": "activity_nav"
                          }
                        },
                        [
                          _c("img", {
                            attrs: { src: item.img, alt: item.title }
                          })
                        ]
                      )
                    : _c(
                        "a",
                        {
                          attrs: {
                            "bi-click": "nav",
                            "data-title": item.name,
                            "data-url": _vm.navType
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleNav(item)
                            }
                          }
                        },
                        [_vm._v(_vm._s(item.name))]
                      )
                ]
              )
            }),
            _c(
              "li",
              {
                staticClass: "tip_area",
                style: {
                  right:
                    _vm.marketGroup.notice_ad && _vm.marketGroup.notice_ad[0]
                      ? "130px"
                      : 0
                }
              },
              [
                _vm._v(" 今日为用户已省"),
                _c("span", [_vm._v("¥2,322,133")]),
                _vm._v("元 ")
              ]
            ),
            _c(
              "li",
              { staticClass: "odd", attrs: { "bi-route": "chuchuang" } },
              [
                _vm.marketGroup.notice_ad && _vm.marketGroup.notice_ad[0]
                  ? _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "jump",
                            rawName: "v-jump",
                            value: _vm.marketGroup.notice_ad[0],
                            expression: "marketGroup.notice_ad[0]"
                          }
                        ],
                        attrs: {
                          "bi-click": "go",
                          "data-title": _vm.marketGroup.notice_ad[0].title,
                          "data-url": _vm.marketGroup.notice_ad[0].url,
                          "data-weizhi": "notice_ad"
                        }
                      },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.marketGroup.notice_ad[0].title) + " "
                        ),
                        _c("div", { staticClass: "group_pop" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.marketGroup.notice_ad[0].img,
                              width: "110",
                              alt: ""
                            }
                          })
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            )
          ],
          2
        )
      ]),
      _c("div", { ref: "searchFixedRef", staticClass: "search-fixed" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "fixed-nav" },
            _vm._l(_vm.searchNav, function(item) {
              return _c(
                "a",
                {
                  key: item.cid,
                  class: _vm.renderCidsActive(item) + " " + _vm.reLoad,
                  on: {
                    click: function($event) {
                      return _vm.handleSearchCids(item)
                    }
                  }
                },
                [_vm._v(_vm._s(item.cname))]
              )
            }),
            0
          ),
          _c("div", { staticClass: "search-fix" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.kw,
                  expression: "kw"
                }
              ],
              attrs: {
                type: "text",
                maxlength: "100",
                placeholder: _vm.placeholder
              },
              domProps: { value: _vm.kw },
              on: {
                keyup: _vm.handleSearchKeyup,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.kw = $event.target.value
                }
              }
            }),
            _c("button", { on: { click: _vm.handleSearch } })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }