import Modal from './index.vue';

const obj = {}

obj.install = function(Vue){
    // 创建组件构造器
    const modalContrustor = Vue.extend(Modal);
    // new 的方式，根据组件构造器，可以创建一个组件对象
     const modal = new  modalContrustor();
    //  将组件对象，手动挂载到某一个元素上
    modal.$mount(document.createElement('div'))
    // modal.$el
    document.body.appendChild(modal.$el)

    Vue.prototype.$modal = modal;
}

export default obj;