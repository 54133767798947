<!--
 * @Author: your name
 * @Date: 2021-08-10 10:24:06
 * @LastEditTime: 2021-11-10 18:51:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/components/IconFont.vue
-->
<template>
  <i @click="handleClick" :class="`iconfont ${type} ${className ? className : ''}`" :style="`${styles ? styles : ''}`" ></i>
</template>

<script>
export default {
  name: "icon-font",
  props: {
    
    type: String,// 图标name
    className:String,// 自定义样式
    styles:String // 自定义style
  },
  methods: {
    handleClick(){
      this.$emit("click");
    }
  },
};
</script>

<!-- 
  Add "scoped" attribute to limit CSS to this component only 
  @图标权限 => @tql
  @https://www.iconfont.cn/manage/index?spm=a313x.7781069.1998910419.14&manage_type=myprojects&projectId=1296411&keyword=&project_type=&page=
-->

<style scoped lang="less">
  @import "https://at.alicdn.com/t/font_1296411_q35asenrip.css?spm=a313x.7781069.1998910419.43&file=font_1296411_q35asenrip.css";
</style>
