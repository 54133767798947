<!--
 * @Author: your name
 * @Date: 2021-08-12 09:15:44
 * @LastEditTime: 2021-11-22 16:21:16
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/components/IntersectionOberverLoading/index.vue
-->
<template>
  <footer class="footer">
    <div class="footer-wrapper" style="padding: 45px 0 10px;">
      <ul>
        <li>网站导航</li>
        <li>
          <a href="?r=/index/index" target="_blank">首页</a>
        </li>
        <li>
          <a href="?r=/l" target="_blank">好货精选</a>
        </li>
        <li>
          <a href="?r=/p" target="_blank">疯抢榜</a>
        </li>
        <li>
          <a href="?r=/ddq" target="_blank">咚咚抢</a>
        </li>
        <li>
          <a href="?r=/nine" target="_blank">9.9包邮</a>
        </li>
        <li class="no">
          <a href="?r=/realtime/zsz" target="_blank">折上折</a>
        </li>
      </ul>
      <p>{{config.site_name}}是一家中立的导购网站，网站中的商品信息均来自于互联网。如商品信息不同，可能是商品信息未及时更新，所有商品信息请以天猫/淘宝店铺内为准。</p>

      <div class="text">
        <span>版权所有 本站内容未经书面许可,禁止一切形式的转载</span>
        <span class="ml">© CopyRight&nbsp;{{new Date().getFullYear()}}</span>
        <a :href="config.yuming ? `//${config.yuming}` : 'javascript:void(0)'" target="_blank" style="padding: 0 8px">{{config.site_name}}{{ config.yuming }}</a>
        <!-- <a
          class="ml"
          :href="config.domian"
          target="_blank"
          rel="nofollow"
          style="padding: 0 8px"
        >{{config.site_name}}{{config.domian}}</a> -->
        <a
          class="miitbeian ml"
          href="https://beian.miit.gov.cn/"
          target="_blank"
          rel="nofollow"
          style="padding: 0 8px"
        >{{config.beianhao}}</a>
        <span style="padding: 0 8px" v-if="config.leftTxt" v-html="config.leftTxt"></span>
        <a
          href="?r=index/feedbackpc"
          target="_blank"
          style="margin-left: 20px;text-decoration: underline;"
        >我要反馈</a>
        <a class="miitbeian ml" href="javascript:void(0)" rel="nofollow">
          <p class="ml" style="display:flex;align-items:center;justify-content:center"></p>
        </a>
        <p
          class="ml"
          v-if="config.custom_script && config.custom_script_title"
          style="display:flex;align-items:center;justify-content:center"
        >
          <img :src="config.custom_script" alt="load..." width="26" />
          <a style="margin-left: 3px;" :href="config.custom_script_url">{{config.custom_script_title}}</a>
        </p>
        
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import IconFont from "@/components/IconFont.vue";
import { watch } from 'vue';
export default {
  data() {
    return {
    };
  },
  name: "footer",
  components: {
    IconFont
  },
  computed: {
    ...mapGetters(["config"])
  },
  mounted() {},
  methods: {},
  watch:{
    config:(state)=>{
      if(state.cnzz_id){
        var cnzz_protocol = (("https:" == document.location.protocol) ? " https://" : " http://");
        const dom = document.createElement("script");
        dom.src =`${cnzz_protocol}s11.cnzz.com/z_stat.php?id=${state.cnzz_id}&show=pic1`;
        dom.type = "text/javascript";
        dom.charset = "utf-8";
        dom.id=`cnzz_stat_icon_${state.cnzz_id}`;
        document.querySelectorAll("body")[0].appendChild(dom);
      }
      if(state.baidu_id){
        var _hmt = _hmt || [];
        var hm = document.createElement("script");
        hm.src = `https://hm.baidu.com/hm.js?${state.baidu_id}`;
        var s = document.getElementsByTagName("script")[0]; 
        s.parentNode.insertBefore(hm, s);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.footer {
  min-height: 180px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  background: rgba(30, 30, 30, 1);
  .footer-wrapper {
    widows: 1200px;
    a {
      color: rgba(255, 255, 255, 0.6);
      text-decoration: none;
      &:hover {
        color: #fff;
        text-decoration: underline;
      }
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
      li {
        margin: 0 10px;
        position: relative;
        z-index: 0;
        &:before {
          content: "";
          background: rgba(255, 255, 255, 0.6);
          height: 14px;
          width: 1px;
          display: block;
          overflow: hidden;
          position: absolute;
          right: -10px;
          top: 50%;
          margin-top: -7px;
          z-index: 5;
        }
        &.no {
          &::before {
            display: none;
          }
        }
      }
    }
    .text {
      line-height: 45px;
    }
  }
}
</style>
