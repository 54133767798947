var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: {
        id: "app",
        "bi-route": _vm.$route.meta ? _vm.$route.meta.eventId || null : null
      }
    },
    [
      _c("headers"),
      _c("div", { staticClass: "mian" }, [_c("router-view")], 1),
      _c("footers"),
      _vm.isShowScrollTop ? _c("scroll-top") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }