var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isHide &&
    _vm.config.site_id &&
    _vm.config.pc_index_lead_switch == 1
    ? _c("div", [
        _c("div", { staticClass: "act-user-modal" }, [
          _c(
            "span",
            { staticClass: "close", on: { click: _vm.handleHide } },
            [
              _c("icon-font", {
                staticStyle: { "font-size": "30px", color: "#fff" },
                attrs: { type: "icon-hide" }
              })
            ],
            1
          ),
          _c("h3", [
            _c("img", {
              attrs: {
                alt: _vm.config.site_name || _vm.config.siteName,
                src:
                  _vm.config.logo ||
                  "https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug0.png"
              }
            })
          ]),
          _c("div", { staticClass: "center" }, [
            _c("h1", [
              _vm._v(
                " 欢迎来到" +
                  _vm._s(
                    _vm.config.site_name ||
                      _vm.config.siteName ||
                      "您的导购商城"
                  ) +
                  " "
              )
            ]),
            _c("p", { staticClass: "desc" }, [
              _vm._v("一款专门为您省钱的官方导购网站")
            ]),
            _c("div", { staticClass: "nr" }, [
              _vm._v(
                " 每日更新数万款天猫淘宝大额优惠券，领券狂省低至1折，想要什么一键全网搜索。精选大牌底价开团，聚划算买一送一，第二件0元，海量折扣应有尽有。专业人工团队严控产品质，帮你花更少钱买好货。 "
              )
            ])
          ]),
          _vm._m(0),
          _c("button", { staticClass: "btn", on: { click: _vm.handleHide } }, [
            _vm._v("我知道了~")
          ])
        ]),
        _c("div", { staticClass: "act-user-modal-bg" })
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [
        _c("img", {
          attrs: {
            src:
              "https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug3.png"
          }
        }),
        _c("p", { staticClass: "name" }, [_vm._v("领券购物省钱")]),
        _c("p", { staticClass: "test" }, [
          _vm._v(
            " 每日更新海量天猫淘宝大额优惠券，领券购物低至1折，让你花少钱买到心仪好物 "
          )
        ])
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src:
              "https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug2.png"
          }
        }),
        _c("p", { staticClass: "name" }, [_vm._v("精选爆款栏目")]),
        _c("p", { staticClass: "test" }, [
          _vm._v(
            " 聚划算精品折上折、疯抢排行、 每日半价、咚咚抢…海量惊喜折扣等你体验 "
          )
        ])
      ]),
      _c("li", [
        _c("img", {
          attrs: {
            src:
              "https://sr.ffquan.cn/dtk_www/20211209/c6onqlhalsbijbagcnug1.png"
          }
        }),
        _c("p", { staticClass: "name" }, [_vm._v("大牌优惠好货")]),
        _c("p", { staticClass: "test" }, [
          _vm._v(
            " 每日更新优质好货、百人品控团队、只为提供超高性价比的前沿好物 "
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }