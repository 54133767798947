/*
 * @Author: your name
 * @Date: 2021-09-26 13:56:04
 * @LastEditTime: 2021-10-12 18:37:59
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/filter/index.js
 */
// import Vue from "vue";
import { AccSub } from "@/utils/utils";

Vue.filter('salesNum',  (value,name='万',state) => {
  if ((value + "").indexOf(name) >= 0) {
    return value;
  }
  if (value < 100) {
    return value;
  }
  if (value < 10000) {
    return state?value+"+":value;
  }
  var result = [],
    counter = 0;
  value = (value || 0).toString().split('');
  var isfor = false;
  for (var i = value.length - 1; i >= 0; i--) {
    counter++;

    if (isfor) {
      result.unshift(value[i]);
    }
    if (!(counter % 4) && i != 0 && !isfor) {
      result.unshift(value[i]);
      result.unshift('.');
      isfor = true;
    }
  }
  var index = result.indexOf('.');
  return (result[index + 1] != "0" ? result.join('') : function () {
    var ret = "";
    result = result.slice(0, index);
    result.forEach(element => {
      ret += element;
    });
    return ret;
  }()) + (name) + `${state?"+":""}`;
})

Vue.filter('salesNums',  (value) => {

  if (value < 10000) {
    return value;
  }
  var result = [],
    counter = 0;
  value = (value || 0).toString().split('');
  var isfor = false;
  for (var i = value.length - 1; i >= 0; i--) {
    counter++;

    if (isfor) {
      result.unshift(value[i]);
    }
    if (!(counter % 4) && i != 0 && !isfor) {
      result.unshift(value[i]);
      result.unshift('.');
      isfor = true;
    }
  }
  var index = result.indexOf('.');
  return (result[index + 1] != "0" ? result.join('') : function () {
    var ret = "";
    result = result.slice(0, index);
    result.forEach(element => {
      ret += element;
    });
    return ret;
  }());
})


Vue.filter('filterTimeline', function (value) {
  const time = (value -(+new Date()));
  if(!value) return '';
  const day = time / (24*60*60*1000);
  return time<0 ? '': day >= 1 ? `仅剩:${parseInt(day)}天` : '';
})

Vue.filter('filterFormat', function (num) {
  var result = [], counter = 0;
  num = (num || 0).toString().split('');
  for (var i = num.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(num[i]);
      if (!(counter % 3) && i != 0) { result.unshift(','); }
  }
  return result.join('');
})

Vue.filter('filterTimeData',function(value = 0){
  if(Number(value)<10){
    return `0${value}`
  } else {
    return value
  }
})


/*
 *  商品活动价格 过滤器
 *  items => 商品基础数据
 *  marketGroupConfig => 网站红的配置
 */ 
Vue.filter('filterPrice', function (items,marketGroupConfig) { 
  return AccSub({num: [items.actualPrice, 0]})
})