import axios from "axios";
// import crypto from "crypto";
import qs from "qs";
// import request from "request";


class dtkSdk {
  constructor(props) {
    this.config = Object.assign(
      {
        appKey: "",
        appSecret: "",
        checkSign: 1,
      },
      props
    );
  }
  makeSign($data, $appSecret) {
    /**验签sign参数加密
     * @param $data
     * @param $appSecret
     * @return string
     */
    let $str = "";
    let $index = 0;
    let $sortPor = [];

    for (let key in $data) {
      if ($data[key] || $data[key] == 0) {
        $sortPor.push(`${key}=${$data[key]}`);
      }
    }
    // 排序
    $sortPor.sort();

    // 转url
    for (let key in $sortPor) {
      $str = `${$str}${$index === 0 ? "" : "&"}${$sortPor[key]}`;
      $index++;
    }
    // md5加密
    // const md5 = crypto.createHash("md5");
    // const $ret = md5.update(`${$str}&key=${$appSecret}`).digest("hex");
    const $ret = md5(`${$str}&key=${$appSecret}`);
    return $ret;
  }

  makeSignNew(props) {
    // md5加密
    // const md5 = crypto.createHash("md5");
    // const $ret = md5
    //   .update(
    //     `appKey=${props.appKey}&timer=${props.timer}&nonce=${props.nonce}&key=${props.appSecret}`
    //   )
    //   .digest("hex");
    const $ret = md5(
      `appKey=${props.appKey}&timer=${props.timer}&nonce=${props.nonce}&key=${props.appSecret}`
    );
    return $ret;
  }

  request(url, props) {
    return this.config.checkSign == 2
      ? this.newCheckSignRequest(url, props)
      : this.checkSignRequest(url, props);
  }
  // 老版本验签
  checkSignRequest(url, { form = {}, ...props }) {
    const data = {
      ...{
        version: this.config.version,
        appKey: this.config.appKey,
      },
      ...form,
    };

    const options = {
      ...{
        ...data,
        ...{ sign: this.makeSign(data, this.config.appSecret) },
      },
      // ...props
    };
    // debugger
    return axios
      .get(
        props.method == "GET" || props.method == "get"
          ? `${url.indexOf("?") > -1 ? url : `${url}?`}${qs.stringify(options)}`
          : url,
        {
          ...options,
          // ...({url:,
          // ...({form:options.method == 'GET' || options.method== 'get' ? {} : options.form})
        }
      )
      .then((res) => {
        // debugger
        return res.data;
      });
  }

  // 新版本验签名
  newCheckSignRequest(url, { form = {}, ...props }) {
    const data = {
      ...{
        appKey: this.config.appKey,
        appSecret: this.config.appSecret,
      },
    };
    // 计算6位随机值
    const random = (min, max) => {
      return Math.floor(Math.random() * (max - min)) + min;
    };

    // 固定参数
    const parms = {
      nonce: random(100000, 999999),
      timer: new Date().getTime(),
    };

    const options = {
      ...{
        url: url, //请求地址
        method: "GET",
        json: true,
        form: {
          ...form,
          ...parms,
          ...{
            signRan: this.makeSignNew({ ...data, ...parms }),
            appKey: data.appKey,
          },
        },
      },
      ...props,
    };
    return new Promise((resolve, reject) => {
      return request(
        {
          ...options,
          ...{
            url:
              options.method == "GET" || options.method == "get"
                ? `${
                    options.url.indexOf("?") > -1
                      ? options.url
                      : `${options.url}?`
                  }${qs.stringify(options.form)}`
                : url,
          },
          ...{
            form:
              options.method == "GET" || options.method == "get"
                ? {}
                : options.form,
          },
        },
        function(error, response, body) {
          if (!error && response.statusCode == 200) {
            //输出返回的内容

            resolve(body);
          } else {
            //输出返回的内容
            reject(response.statusCode, body);
          }
        }
      );
    });
  }
}

export default dtkSdk;
