<!--
 * @Author: your name
 * @Date: 2021-08-12 09:15:44
 * @LastEditTime: 2021-11-30 15:40:19
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/dtk_vue_cms_server_v2/src/components/IntersectionOberverLoading/index.vue
-->
<template>
  <div>
    <!-- 头部广告位置 -->
    <div
      class="act_top"
      v-if="
        isNavActive(nav[0]) &&
        isShowAct &&
        marketGroup.top_banner &&
        marketGroup.top_banner.length > 0
      "
      bi-route="chuchuang"
    >
      <span class="close" @click="handleHideAct">
        <icon-font type="icon-hide"></icon-font>
      </span>
      <a v-jump="marketGroup.top_banner[0]" target="_blank"  data-weizhi="top_banner" :data-url="marketGroup.top_banner[0].url" :data-title="marketGroup.top_banner[0].title" bi-click="go">
        <img :src="marketGroup.top_banner[0].img" />
      </a>
    </div>
    <header class="header">
      <div class="header_search">
        <a class="logo" href="?r=index/index" v-if="config.logo">
          <img :alt="config.siteName || config.site_name" :src="config.logo" />
        </a>
        <a class="logo" href="?r=index/index" v-if="!config.logo">{{
          config.siteName || config.site_name
        }}</a>
        <div class="search" bi-route="search">
          <div class="tag">
            <a
              @click="handleTagType(item)"
              :class="{ active: item.type == tagIndex }"
              v-for="(item, key) in tag"
              v-if="item.isShow"
              :key="key"
              :bi-click="tabs"
              :data-tabs="item.eventId || ''"
              >{{ item.name }}</a
            >
          </div>
          <div class="search-form">
            <input
              @keyup="handleSearchKeyup"
              maxlength="100"
              @focus="handleFocus"
              @blur="handleBlur"
              v-model="kw"
              :placeholder="placeholder"
              ref="inputDom"
            />
            <a @click="handleClose" class="close">
              <icon-font type="icon-close" v-show="kw" />
            </a>
            <button
              @click="handleSearch"
              bi-click="searchbt"
              :data-tabs="curTagName"
              :data-kw="kw ? decodeURIComponent(encodeURIComponent(kw)) : ''"
            >
              搜 索
            </button>
            <ul
              v-if="searchTag.length > 0"
              class="kw-list"
              v-show="isInputFocus"
            >
              <li
                v-for="item in searchTag"
                @click="handleSearchKw(item)"
                :key="item"
                :class="{ active: item.kw == kw }"
              >
                {{ item.kw }}
              </li>
            </ul>
          </div>
          <div class="search-list" v-if="tagList.length != 0">
            <a
              v-for="(item, index) in tagList"
              bi-click="tag"
              :data-index="index"
              :data-kw="item"
              :data-tabs="curTagName"
              :key="item"
              @click="handleSearch({ kw: item })"
              >{{ item }}</a
            >
          </div>
        </div>
        <div
          class="act"
          bi-route="chuchuang"
          v-if="
            marketGroup &&
            marketGroup.search_right &&
            marketGroup.search_right[0]
          "
        >
          <a
            v-jump="marketGroup.search_right[0]"
            bi-click="go"
            :data-title="marketGroup.search_right[0].title"
            :data-url="marketGroup.search_right[0].url"
            data-weizhi="search_right"
          >
            <img :src="marketGroup.search_right[0].img" />
          </a>
        </div>
      </div>
      <nav class="nav">
        <ul>
          <li
            v-for="(item, key) in nav"
            :class="{
              active: isNavActive(item),
              'nav-pic': item.type == 'act',
            }"
            :key="key"
            :bi-route="item.type == 'act' ? chuchuang : daohang"
          >
            <i v-if="item.isNew" class="new">NEW</i>

            <a
              v-if="item.type == 'act'"
              bi-click="go"
              :data-title="marketGroup.activity_nav[0].title"
              :data-url="marketGroup.activity_nav[0].url"
              data-weizhi="activity_nav"
              v-jump="item"
            >
              <img :src="item.img" :alt="item.title" />
            </a>
            <a
              bi-click="nav"
              :data-title="item.name"
              :data-url="navType"
              v-else
              @click="handleNav(item)"
              >{{ item.name }}</a
            >
          </li>
          <li
            class="tip_area"
            :style="{
              right:
                marketGroup.notice_ad && marketGroup.notice_ad[0] ? '130px' : 0,
            }"
          >
            使用{{ shop_name }}购物，预计帮您节省¥3726.2
          </li>
          <li class="odd" bi-route="chuchuang">
            <a
              v-if="marketGroup.notice_ad && marketGroup.notice_ad[0]"
              v-jump="marketGroup.notice_ad[0]"
              bi-click="go"
              :data-title="marketGroup.notice_ad[0].title"
              :data-url="marketGroup.notice_ad[0].url"
              data-weizhi="notice_ad"
            >
              {{ marketGroup.notice_ad[0].title }}
              <div class="group_pop">
                <img :src="marketGroup.notice_ad[0].img" width="110" alt />
              </div>
            </a>
          </li>
        </ul>
      </nav>
      <div class="search-fixed" ref="searchFixedRef">
        <div class="row">
          <div class="fixed-nav">
            <a
              @click="handleSearchCids(item)"
              v-for="item in searchNav"
              :class="`${renderCidsActive(item)} ${reLoad}`"
              :key="item.cid"
              >{{ item.cname }}</a
            >
          </div>
          <div class="search-fix">
            <input
              type="text"
              @keyup="handleSearchKeyup"
              maxlength="100"
              v-model="kw"
              :placeholder="placeholder"
            />
            <button @click="handleSearch"></button>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import IconFont from "@/components/IconFont.vue";
import { GetCookie, SetCookie } from "@/utils/utils";

export default {
  data() {
    return {
      isShowAct: !GetCookie("CMS-ACT-TOP"),
      isInputFocus: false,
      inputValue: "",
      reLoad: false, //触发渲染
      placeholder: "",
      kw: "",
      curTagName: "taobao",
      tagIndex: 1,
      shop_name: "",
      navType: "/",
      searchNav: [{ cid: -1, cname: "首页" }],
      isTag: false,
      tag: [
        {
          name: "淘宝",
          type: 1,
          url: "/l",
          isShow: true,
          eventId: "taobao",
          query: { r: "l", search_type: "tb" },
        },
        {
          name: "京东",
          type: 2,
          isShow: false,
          url: "/l/jdlist",
          eventId: "jd",
          query: { r: "l", search_type: "jd" },
        },
        {
          name: "拼多多",
          type: 3,
          isShow: false,
          url: "/l/ddlist",
          eventId: "pdd",
          query: { r: "l", search_type: "dd" },
        },
        {
          name: "唯品会优惠",
          type: 4,
          isShow: false,
          url: "/l/wphlist",
          eventId: "wph",
          query: { r: "l", search_type: "wph" },
        },
      ],
      searchTag: [],
      nav: [
        {
          name: "首页",
          url: ["/index/index", "/"],
          query: { r: "/index/index" },
        },
        {
          name: "好货精选",
          url: ["/l", "/l/jdlist", "/l/ddlist", "/l/dd", "/l/jd", "/l/d"],
          query: { r: "l" },
        },
        { name: "疯抢榜", url: "/p", query: { r: "p" } },
        { name: "9块9包邮", url: "/nine", query: { r: "nine" } },
        { name: "咚咚抢", url: "/ddq", query: { r: "ddq" } },
        // {
        //   name: "每日半价",
        //   url: "/every/half",
        //   isNew: true,
        //   query: { r: "every/half" },
        // },
        {
          name: "折上折",
          url: "/realtime/zsz",
          isNew: true,
          query: { r: "realtime/zsz" },
        },
      ],
      tagList: [],
      isSetTag: true,
    };
  },
  name: "header",
  props: {},
  components: {
    IconFont,
  },
  computed: {
    ...mapGetters(["marketGroup", "config"]),
  },
  methods: {
    handleHideAct() {
      SetCookie("CMS-ACT-TOP", 1, 24);
      this.isShowAct = false;
    },
    handleFocus() {
      this.isInputFocus = true && !!this.kw;
    },
    renderShopName(config) {
      if (config.site_name?.length <= 8) {
        this.shop_name = config.site_name;
      } else {
        this.shop_name =
          config.site_name && config.site_name.slice(0, 8) + "...";
      }
    },
    handleBlur() {
      return;
      setTimeout(() => {
        this.isInputFocus = false;
      }, 100);
    },
    handleClose() {
      this.kw = "";
      this.searchTag = [];
      this.isInputFocus = false;
      // this.handleSearch();
    },
    renderCidsActive(item) {
      return this.$route.query.cids == item.cid ? "active" : "";
    },
    handleSearchCids(item) {
      if(item.cname=="首页"){
        this.$router.push({
          path: this.$route.path == "/" ? "/index/index" : "/",
        });
      }else{
        this.$router.push({
          path: `/l`,
          query: {
            cids: item.cid,
            from: "head",
          },
        });
      }
    },
    init() {
      this.$services.getDTKOpenCategoryTop100().then((res) => {
        if (res.code != 0) {
          return false;
        }
        this.tagList = res.data.hotWords.filter((data, key) => key <= 9);
      });
      this.$services.getDTKOpenCategorySuper().then((res) => {
        if (res.code != 0) return false;
        this.searchNav = [...this.searchNav, ...res.data];
      });
      if (this.$refs.searchFixedRef) {
        this.scrollSearchFixed();
      }
    },
    scrollSearchFixed() {
      const $el = this.$refs.searchFixedRef;
      const className = $el.className;
      window.addEventListener("scroll", () => {
        if (window.scrollY > 400 && $el) {
          $el.className = className + " show";
        } else if ($el) {
          $el.className = className;
        }
      });
    },
    isNavActive(item) {
      let ret = false;
      if (typeof item.url == "object") {
        ret = item.url.filter(
          (res) => res == `${this.navType}`.split("?")[0]
        )[0];
      }

      if (!ret) {
        return this.navType == item.url;
      }
      return ret;
    },
    handleTagType(item) {
      console.log(item);
      this.tagIndex = item.type;
      this.curTagName = item.eventId;
      this.handleSearch();
    },
    handleSearch(props) {
      this.isSetTag = false;
      if (props && props.kw) {
        this.kw = decodeURIComponent(props.kw) ;
      }
      this.kw = this.kw || "";
      const item = this.tag.filter((res) => res.type == this.tagIndex)[0];
      window.scrollTo(0, 0);
      var reg = /([a-zA-Z0-9]{16,25}-[a-zA-Z0-9]{16,25})/
      let id = this.getQueryString('id',this.kw);
      let origin_id = "";
      if(reg.test(this.kw)||reg.test(id)){
        origin_id = id || this.kw
      }
      this.$router.push({
        path: item.url,
        query: {
          ...(this.kw
            ? { kw: encodeURIComponent(decodeURIComponent(this.kw)) }
            : {}),
          t: 223,
          origin_id: origin_id
          // ...item.query
        },
      });
    },
    //获取输入地址中的id参数
    getQueryString(key, url) {
      try {
        let reg = new RegExp("(^|&)" + key + "=([^&]*)(&|$)", "i");
        let data = url.split('?')[1].match(reg);
        if (data != null) {
          return decodeURI(data[2])
        } else {
          return null
        }
      } catch (error) {
        return null
      }
    },
    handleSearchKw(item) {
      this.searchTag = [];
      this.handleSearch({ kw: item.kw });
    },
    handleSearchKeyup(event) {
      this.isSetTag = true;
      // 按下回车键 触发
      if (event.keyCode == 13) {
        this.searchTag = [];
        this.handleSearch();
        return false;
      }
      var num = this.searchTag.length;
      this.isInputFocus = true;
      // 按下下键 触发
      if (event.keyCode == 40 && num > 0) {
        let key = -1;
        for (let index = 0; index < num; index++) {
          if (this.searchTag[index].kw == this.kw) {
            key = index;
          }
        }
        this.kw =
          key + 1 < num ? this.searchTag[key + 1].kw : this.searchTag[0].kw;
        return false;
      }
      // 按下上键 触发
      if (event.keyCode == 38 && num > 0) {
        let key = 0;
        for (let index = 0; index < num; index++) {
          if (this.searchTag[index].kw == this.kw) {
            key = index;
          }
        }
        this.kw =
          key - 1 < 0 ? this.searchTag[num - 1].kw : this.searchTag[key - 1].kw;

        return false;
      }
      // 联想词逻辑
      clearTimeout(this.setTime);
      this.setTime = setTimeout(() => {
        this.$services
          .getDTKOpenSearchSuggestion({ keyWords: decodeURIComponent(this.kw) , type: 3 })
          .then((res) => {
            if (res.code != 0) {
              this.searchTag = [];
              return false;
            }
            if (this.isSetTag) {
              this.searchTag = res.data;
            }
          });
      }, 100);
    },
    handleNav(item) {
      this.navType = typeof item.url == "object" ? item.url[0] : item.url;
      // if (item.name == "首页") {
      //   window.location.href = `?r=${item.query.r}`;
      //   return false;
      // }
      this.$router.push({
        path: this.navType,
      });
    },
    reloadConfig(){
      const state = this.config;
      const isPdd = state.pdd && state.pdd.is_open === "1";
      const isJd = state.jd && state.jd.is_show === "1";
      const isWph = state.vip && state.vip_switch === "1";
      this.tag = this.tag.map((res) => {
        let ret = {};
        if (res.type == 3 && isPdd) {
          ret.isShow = true;
        }
        if (res.type == 2 && isJd) {
          ret.isShow = true;
        }
        if (res.type == 4 && isWph) {
          ret.isShow = true;
        }
        return { ...res, ...ret };
      });
      // this.isTag = true;
      this.renderShopName(state);
      //isPdd ? "/拼多多" : ""隐藏pdd敏感信息
      this.placeholder = `粘贴淘宝${isJd ? "/京东" : ""}${isWph ? "/唯品会" : ""}商品标题查隐藏优惠券`;
    }
  },
  mounted() {
    this.kw = decodeURIComponent(this.$route.query.kw||'') ;

    this.navType = this.$route.fullPath || "/";
    // debugger
    this.tagIndex =
      this.$route.fullPath.indexOf("jd") > 0
        ? 2
        : (this.$route.fullPath.indexOf("dd") > 0 && this.$route.fullPath.indexOf("ddq") <= 0)
        ? 3
        : this.$route.fullPath.indexOf("wph") > 0 ? 4 : 1;
    this.init();
    if (this.config.site_name) {
      this.renderShopName(this.config);
    }
    this.reloadConfig();
  },
  watch: {
    $route(state) {
      this.searchTag = [];
      this.kw = decodeURIComponent(this.$route.query.kw||'');
      this.navType = this.$route.fullPath || "/";
      this.reLoad = !this.reLoad;
      this.tagIndex =
        this.$route.fullPath.indexOf("jd") > 0
          ? 2
          : (this.$route.fullPath.indexOf("dd") > 0 && this.$route.fullPath.indexOf("ddq") <= 0)
          ? 3
          : this.$route.fullPath.indexOf("wph") > 0 ? 4 : 1;
    },
    config(state) {
      this.reloadConfig();
    },
    marketGroup: function (state) {
      if (state.activity_nav) {
        for (let index = state.activity_nav.length - 1; index >= 0; index--) {
          const element = state.activity_nav[index];
          this.nav.splice(1, 0, { ...element, ...{ type: "act" } });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.act_top {
  width: 100%;
  position: relative;
  overflow: hidden;
  .close {
    color: #fff;
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 24px;
    margin-top: -15px;
    z-index: 5;
    cursor: pointer;
    display: block;
    line-height: 30px;
    i {
      font-size: 24px;
    }
  }
  a {
    display: block;
    width: 3000px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -1500px;
    position: relative;
    left: 50%;
    z-index: 0;
    cursor: pointer;
    img {
      display: block;
      margin: 0;
    }
  }
}
.header {
  height: 154px;
  position: relative;
  z-index: 5;

  .header_search {
    background: #fff;
    height: 119px;
    width: 1200px;
    margin: auto;
    display: flex;
    align-items: center;
    .act {
      width: 220px;
      height: 80px;
      position: relative;
      right: -50px;
      img {
        width: 220px;
        height: 80px;
      }
    }
    .logo {
      width: 320px;
      height: 90px;
      display: inline-block;
      float: left;
      background-size: inherit;
      text-align: left;
      line-height: 80px;
      font-size: 36px;
      margin: 4px 10px 0 0;
      color: #333;
      overflow: hidden;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .search {
    .tag {
      display: flex;
      padding-left: 18px;
      a {
        height: 25px;
        font-size: 14px;
        color: #fe2e54;
        line-height: 27px;
        padding: 0 7px;
        margin-right: 10px;
        cursor: pointer;
        &.active {
          background: linear-gradient(180deg, #f65d49 0, #fe2e54 100%);
          border-radius: 5px 5px 0 0;
          color: #fff;
        }
      }
    }
    .search-form {
      width: 580px;
      height: 40px;
      position: relative;
      z-index: 50;
      .close {
        position: absolute;
        right: 90px;
        top: 12px;
        z-index: 20;
        font-style: 20px;
        color: #999;
        cursor: pointer;
        &:hover {
          color: #666;
        }
      }
      .kw-list {
        position: absolute;
        width: 466px;
        top: 38px;
        left: 30px;
        border: solid 1px #ccc;
        background: #fff;
        max-height: 400px;
        overflow: hidden;
        overflow-y: auto;
        li {
          line-height: 30px;
          color: #666;
          cursor: pointer;
          font-size: 13px;
          padding: 0 10px;
          &:hover,
          &.active {
            background: #f1f1f1;
          }
        }
      }
      input {
        border-radius: 20px 0 0 20px;
        width: 500px;
        height: 40px;
        box-sizing: border-box;
        padding: 0 40px 0 42px;
        background: url(./images/search.png) no-repeat 18px 10px;
        background-size: 16px;
        border: 2px solid #fe2e54 !important;
        font-size: 14px;
        float: left;
        &:focus,
        &:active {
          border: 2px solid #fe2e54 !important;
          color: #333;
        }
        &::placeholder {
          font-size: 14px;
          color: #b7b7b7;
        }
      }
      button {
        width: 80px;
        height: 40px;
        border-radius: 0 20px 20px 0;
        display: inline-block;
        background-color: #fe2e54;
        color: #fff;
        letter-spacing: 2px;
        float: right;
        border: 0;
        line-height: 21px;
        cursor: pointer;
        font-size: 16px;
        font-family: "Microsoft YaHei";
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        &:hover {
          background: #fe2e54;
        }
      }
    }
    .search-list {
      width: 580px;
      overflow: hidden;
      position: relative;
      top: 6px;
      height: 20px;
      padding-left: 0;
      margin-bottom: 8px;
      a {
        font-size: 12px;
        margin: 0 25px 0 0;
        color: #929292;
        &.new {
          color: #fe2e54;
        }
      }
    }
  }
  @keyframes myfirst {
    0%,
    100% {
      transform: translate(0, 0);
    }

    50% {
      transform: translate(0, -2px);
    }
  }

  .nav {
    background: #fe2e54;
    height: 35px;
    color: #fff;
    ul {
      width: 1200px;
      display: flex;
      margin: auto;
      align-items: center;
      height: 35px;
      position: relative;
      z-index: 0;
      li.odd {
        position: absolute;
        right: 0;
        top: 0;
        z-index: 2;
        width: 130px;
        box-sizing: border-box;
        a {
          text-align: center;
          &:hover {
            background: none;
          }
        }
        &:hover {
          background: #fff;
          color: #d70d1c;
          .group_pop {
            display: block;
          }
        }
        .group_pop {
          position: absolute;
          top: 35px;
          background: #fff;
          width: 100%;
          z-index: 9;
          left: 0;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.07);
          border-radius: 0 0 10px 10px;
          display: none;
          padding-bottom: 10px;
          img {
            height: 110px;
            display: block;
            margin: auto;
          }
        }
      }
      li.tip_area {
        position: absolute;
        right: 0;
        top: 0;
        line-height: 35px;
        font-size: 13px;
        color: #fff2c7;
        font-family: MicrosoftYaHei;
      }
      li {
        position: relative;
        z-index: 0;
        &.nav-pic {
          width: 117px;

          img {
            position: absolute;
            width: 117px;
            left: 0;
            bottom: 0;
          }
        }

        .new {
          color: #ff2d54;
          padding: 1px 3px;
          position: absolute;
          top: -0px;
          right: -9px;
          z-index: 99;
          text-align: center;
          background: #fff255;
          border-radius: 7.5px 7.5px 7.5px 0;
          animation: myfirst 1s ease-in-out 1.5s infinite alternate;
          font-style: normal;
        }
        &.active {
          a {
            background: rgba(0, 0, 0, 0.1);
          }
        }
        a {
          padding: 0 30px;
          display: block;
          height: 35px;
          line-height: 35px;
          font-size: 14px;
          &:hover {
            background: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
}
.search-fixed {
  position: fixed;
  height: 50px;
  top: 0;
  z-index: 9999;
  min-width: 1200px;
  box-shadow: 0 2px 4px 0 rgba(170, 170, 170, 0.2);
  background: #fff;
  text-align: center;
  width: 100%;
  display: none;
  &.show {
    display: block;
  }
  .row {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    .fixed-nav {
      display: flex;
      a {
        border-bottom: solid 2px #fff;
        color: #666;
        font-size: 14px;
        text-align: center;
        margin-right: 15px;

        height: 50px;
        box-sizing: border-box;
        line-height: 50px;
        display: block;
        &.active {
          border-bottom-color: #fe2e54;
        }
      }
    }
    .search-fix {
      border: 2px solid #fe2e54 !important;
      position: relative;
      border-radius: 20px;
      width: 340px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      input {
        width: 280px;
        color: #333;
        font-size: 12px;
        border: 0;
        background: none;
        padding-left: 15px;
      }
      button {
        width: 50px;
        height: 32px;
        color: #fff;
        background: url(./images/srcicos.png) #fe2e54 center center no-repeat;
        background-size: auto 60%;
        border: 0;
        cursor: pointer;
        // border-radius: 0 20px 20px 0; cursor: pointer;
      }
    }
  }
}
</style>
