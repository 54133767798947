/*
 * @Author: your name
 * @Date: 2020-09-09 14:17:28
 * @LastEditTime: 2021-10-12 15:31:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/demo-ui/vue-cli/demo5/src/store/getters.js
 */
export const getters = {
    userInfo: state => state.userInfo,
    marketGroup: state => state.marketGroup,
    config: state => state.config,
    footerTabbar: state => state.footerTabbar,
    marketGroupConfig: state => state.marketGroupConfig,
    
}
