/*
 * @Author: your name
 * @Date: 2020-09-08 14:19:30
 * @LastEditTime: 2021-11-22 16:12:50
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/demo-ui/vue-cli/demo5/src/main.js
 */
import 'babel-polyfill';
import BIJsdk from "dtk-bi-jssdk";
import { isProjectUpdate } from 'isprojectupdate';
import qs from "qs";
import App from "./App.vue";
import Modal from "./components/Modal/index.js";
import "./directive/index";
import "./filter/index";
import router from "./router";
import * as Services from "./services/index";

import store from "./store";
const dom = document.getElementById("mid")
  ? document.getElementById("mid").getAttribute("content")
  : false;
  console.log(process.env.NODE_ENV);
window.$mid =
  dom ||
  window.$mid ||
  window.sessionStorage.getItem("CMS-MID") ||
  (process.env.NODE_ENV === "development" ? 1 : "");
// const $BIJsdk = {
//     onShow: function () { },
//     onEvent: function () { }
// }
Vue.config.productionTip = false;

const $BIJsdk = new BIJsdk({
  project: "cms", // project 接口收集参数 判别产品类型
  uid: window.$mid, // 登录用户id
  eventId: "shouye", // 页面表示如：‘商品首页，搜索页面’
  env: process.env.NODE_ENV == 'development' ? "prod" : 'prod', // test 默认 prod 线上环境
  routerType: 2, // 1 服务端路由 2 前端路由
  isExposure: 1, // 曝光上报 默认 1 开启  0 关闭
});
window.$BIJsdk = $BIJsdk;
// Vue.prototype.$BIJsdkToErr = $BIJsdkToErr;
Vue.config.errorHandler = function(err, vm, info) {
  if ($BIJsdk && process.env.NODE_ENV == "production") {
    $BIJsdk.onError({
          type: 'vueError',
          desc: err.stack,
      });
  }
}

// 提示
Vue.use(Modal);
// Vue.use(Lazyload, {
//     loading: "https://sr.ffquan.cn/cms_pic/20210813/c4b0drf6vrkdtgsh7q100.gif",
// });
Vue.prototype.$services = {
  ...Services,
};
Vue.prototype.$BIJsdk = $BIJsdk;
Vue.prototype.$UA = (function() {
  const u = navigator.userAgent,
    app = navigator.appVersion;
  return {
    trident: u.indexOf("Trident") > -1,
    presto: u.indexOf("Presto") > -1,
    webKit: u.indexOf("AppleWebKit") > -1,
    gecko: u.indexOf("Gecko") > -1 && u.indexOf("KHTML") == -1,
    mobile: !!u.match(/AppleWebKit.*Mobile.*/),
    ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
    android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
    iPhone: u.indexOf("iPhone") > -1,
    iPad: u.indexOf("iPad") > -1,
    webApp: u.indexOf("Safari") == -1,
    souyue: u.indexOf("souyue") > -1,
    superapp: u.indexOf("superapp") > -1,
    weixin: u.toLowerCase().indexOf("micromessenger") > -1,
    Safari: u.indexOf("Safari") > -1,
    weibo: u.toLowerCase().indexOf("weibo") > -1,
    isApp: u.indexOf("isApp/1") > -1, // 1:小慧  2:独立 3:cms
    productType: u.indexOf("productType/1") > -1,
    isAlone: u.indexOf("productType/2") > -1,
  };
})();
let versionTime = Date.now();
if (process.env.NODE_ENV == "production"){
  isProjectUpdate('https://cmsstaticv2.ffquan.cn/pc/asset-manifest.json')
}
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    // document.title = to.meta.title;
    document.title = `${to.meta.title}-${
      window.title ? `-${window.title}` : ""
    }`;
  }

  if ((to.path.includes("/l/d") && to.path != "/l/ddlist") || to.path == "/l/jd" || to.path == "/l/dd") {
    document.body.style.opacity = "0";
  }

  if (to.query.AppToken) {
    window.APPTOKEN = to.query.AppToken;
    // window.localStorage.setItem('APPTOKEN',to.query.AppToken);
  }
  // 开发模式默认站点id

  window.$tempId =
    window.$tempId || window.sessionStorage.getItem("CMS-TEMP-ID");
  // url地址获取 站点id
  const items = qs.parse(window.location.search.split("?")[1]);
  const mid = items.mid || to.query.mid;
  const tempId = items.temp_id || to.query.temp_id;

  // 获取url地址中的站点id
  if (mid) {
    window.$mid = mid;
    window.sessionStorage.setItem("CMS-MID", mid);
  }
  // 预览模式的模版id
  if (tempId) {
    window.sessionStorage.setItem("CMS-TEMP-ID", tempId);
    window.$tempId = tempId;
  }
  // 路由跳转时检测是否更新了项目
  if (process.env.NODE_ENV == "production" && versionTime+1000*60*60 < Date.now()) {
    versionTime = Date.now();
    isProjectUpdate('https://cmsstaticv2.ffquan.cn/pc/asset-manifest.json').then(res=>{
      if(res){
        window.location.reload(true)
      }
    });
  }
  next();
  if (to.path.includes("/detail")) {
    return;
  }
  window.scrollTo(0, 0);
});

router.onReady((res) => {
  if(window.$config && window.$config.is_site_stop == '1'){
    router.push({
      path: "/notfound/index",
    });
  }
  if (res.path == '/' && res.meta && res.meta.eventId) {
    $BIJsdk.onShow({
      eventId: res.meta.eventId,
      isExposure: 0, // 曝光上报 默认 1 开启  0 关闭
    });
  }
})

router.afterEach((to, from, next) => {
  if (to.path !== from.path) {
    setTimeout(() => {
      try {
        if (to.meta.eventId) {
          $BIJsdk.onShow({
            eventId: to.meta.eventId,
            isExposure: 0, // 曝光上报 默认 1 开启  0 关闭
          });
        }
      } catch (error) {}
    }, 0);
  }
  
  // 埋点
  try {
    if (window.DTKsatc) {
      DTKsatc.eventStat({
        name: "PageViweEvent",
        params: { desc: "页面访问" },
      });
    } else {
      setTimeout(() => {
        if (window.DTKsatc) {
          DTKsatc.eventStat({
            name: "PageViweEvent",
            params: { desc: "页面访问" },
          });
        }
      }, 800);
    }
  } catch (err) {}
});

Vue.config.productionTip = false;

// 重现初始化 地址跳转组件
// Vue.prototype.$routes = new RouterQuery(router);
window.$myApp = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
