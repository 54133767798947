/*
 * @Author: your name
 * @Date: 2020-09-09 14:17:28
 * @LastEditTime: 2021-11-09 17:14:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /www/demo-ui/vue-cli/demo5/src/store/state.js
 */
export const state = {
  userInfo: {},
  marketGroup: {},
  // 网站配置
  config: {},
  footerTabbar: {
    index: 0,
  },
  // 活动配置 @接口文档 http://yapi.buydance.com/project/50/interface/api/460
  marketGroupConfig: {},
};
